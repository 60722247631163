import React, { Component } from 'react';
import { Modal, Input, Select, Icon, Button, message } from 'antd';
import { CDN_URL } from '../../../../config';
import LibraryModal from '../../Library/LibraryModal';
import './index.scss';
import { addReview } from '../../../../helpers/ajax/reviewsAjax';
const { Option } = Select;

class AddReviewModal extends Component {
  state = {
    review: {
      id: undefined,
      review_name: "",
      review_reviewtext: "",
      review_stars: 0,
      review_pools: [],
      review_image: ""
    },
    libraryModalVisible: false,
    libraryModalTab: 'library',
    edit: false,
    errors: {}
  }

  resetModal(review = {}) {
    this.setState({
      review: {
        id: review._id ? review._id["$id"] : undefined,
        review_name: review.review_name ? review.review_name : "",
        review_reviewtext: review.review_reviewtext ? review.review_reviewtext : "",
        review_stars: review.review_stars ? review.review_stars : 0,
        review_pools: review.review_pools ? review.review_pools : [],
        review_image: review.review_image ? review.review_image : ""
      },
      libraryModalVisible: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    this.resetModal(nextProps.review);
  }

  setField(field, value) {
    if (field === "review_stars") {
      value = this.state.review.review_stars === value ? value - 1 : value;
    }
    if (field === "review_image") {
      value = value.media_filename ? value.media_filename : '';
    }
    this.setState({
      review: {
        ...this.state.review,
        [field]: value
      }
    })
  }

  toggleLibraryModal(tab = 'library') {
    this.setState({
      libraryModalVisible: !this.state.libraryModalVisible,
      libraryModalTab: tab
    })
  }

  onImageChoose(media) {
    this.setField("review_image", media);
    this.toggleLibraryModal();
  }

  saveReview() {
    const review = this.state.review;
    let errors = {};
    if (review.review_name.trim().length === 0) {
      errors["review_name"] = "This is a required field";
    }
    if (review.review_reviewtext.trim().length === 0) {
      errors["review_reviewtext"] = "This is a required field";
    }
    if (review.review_image.trim().length === 0) {
      errors["review_image"] = "This is a required field";
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors
      })
    } else {
      this.setState({
        errors: {}
      }, () => {
        addReview(this.props.match.params.domid, review)
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Review saved successfully!");
              if (this.props.onAddReview) {
                this.props.onAddReview(res);
              }
            } else {
              message.error(res.data.msg);
            }
          }).catch(e => {
            message.error(e.message)
          })
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          className="add-review-modal"
          visible={this.props.visible}
          title="Add New Review"
          afterClose={() => this.resetModal()}
          onCancel={() => this.props.toggle()}
          footer={null}
        >
          <div className="add-review-form">
            <div className={`ant-form-item-control ${this.state.errors.review_name ? 'has-error' : ''}`}>
              <label>Reviewer's Name</label>
              <Input placeholder="Enter Reviewer's Name" value={this.state.review.review_name} onChange={e => this.setField("review_name", e.target.value)} />
              {this.state.errors.review_name && <div className="ant-form-explain">{this.state.errors.review_name}</div>}
            </div>

            <div className={`ant-form-item-control ${this.state.errors.review_image ? 'has-error' : ''}`}>
              <label>Reviewer's Image</label>
              <div className="upload-actions">
                {this.state.review.review_image.length > 0 &&
                  <div className="upload-action">
                    <img src={`${CDN_URL}/images/reviews/${this.state.review.review_image}`} alt="reviewer" style={{ width: '100%', height: '100%' }} />
                  </div>
                }
                <div className="upload-action" onClick={() => this.toggleLibraryModal("library")}>
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
                    Library
                  </p>
                </div>
                <div className="upload-action" onClick={() => this.toggleLibraryModal("upload")}>
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
              </div>
              {this.state.errors.review_image && <div className="ant-form-explain">{this.state.errors.review_image}</div>}
            </div>

            <div className={`ant-form-item-control ${this.state.errors.review_reviewtext ? 'has-error' : ''}`}>
              <label>User Review</label>
              <Input.TextArea placeholder="Enter User Review" value={this.state.review.review_reviewtext} onChange={e => this.setField("review_reviewtext", e.target.value)} />
              {this.state.errors.review_reviewtext && <div className="ant-form-explain">{this.state.errors.review_reviewtext}</div>}
            </div>

            <div className="ant-form-item-control">
              <label>Enter Star Rating</label>
              <div style={{ marginTop: "0.71rem" }}>
                {Array(5).fill(0).map((_, i) => (
                  <Icon
                    key={i}
                    type="star"
                    theme={(i + 1) <= this.state.review.review_stars ? "filled" : "outlined"}
                    style={{ color: "#FFBA1F", fontSize: '1.11rem', marginRight: "0.52rem", cursor: "pointer" }}
                    onClick={() => this.setField("review_stars", (i + 1))} />
                ))}
              </div>
            </div>

            <div className="ant-form-item-control">
              <label>Choose or Create a Pool</label>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Enter Pool Name"
                value={this.state.review.review_pools}
                onChange={e => this.setField("review_pools", e)}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {this.props.pools.map((p) => <Option value={p._id} key={p._id}>{p._id}</Option>)}
              </Select>
            </div>

            <div className="ant-form-item-control" style={{ marginTop: "2.35rem" }}>
              <Button className="submit-btn" size="large" onClick={() => this.saveReview()}>Save Review</Button>
            </div>
          </div>
        </Modal>

        <LibraryModal
          {...this.props}
          onCancel={() => this.toggleLibraryModal()}
          tab={this.state.libraryModalTab}
          visible={this.state.libraryModalVisible}
          uploadType="reviews"
          onChoose={(media) => this.onImageChoose(media)} />
      </React.Fragment >
    )
  }

}

export default AddReviewModal;