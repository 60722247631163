import React, { Component } from 'react';
import { Input, Select, Icon, Switch, Button } from 'antd';
import { get } from 'lodash';
import FormattingField from '../../../Partials/FormattingField';
import DesignSettings from '../DesignSettings';
import LibraryModal from '../../../Library/LibraryModal';
import { CDN_URL } from '../../../../../config';
import { ReactComponent as Template1Icon } from '../../../../../assets/images/callout_templates/template1.svg';
import { ReactComponent as Template2Icon } from '../../../../../assets/images/callout_templates/template2.svg';
import { ReactComponent as Template3Icon } from '../../../../../assets/images/callout_templates/template3.svg';
import { ReactComponent as Template4Icon } from '../../../../../assets/images/callout_templates/template4.svg';

const { Option } = Select;

class RealtimeVisitorAlert extends Component {
  state = {
    libraryModalVisible: false,
    libraryModalTab: 'library'
  }
  toggleLibraryModal(tab = 'library') {
    this.setState({
      libraryModalVisible: !this.state.libraryModalVisible,
      libraryModalTab: tab
    })
  }
  onImageChoose(media) {
    this.setState({
      libraryModalVisible: false
    });
    this.props.onUpdate("camp_realtimevisitor.staticimage.name", media.media_filename);
  }

  render() {
    return (
      <div className="power-trails-alert" style={{ position: "relative" }}>
        <div id="tab1">
          <div className="tab-header" style={{ marginBottom: 21 }}>
            <h3>Customize Alert</h3>
            <Icon
              type="plus"
              className="right"
              onClick={() => this.props.toggle()}
              style={{ fontSize: "24px", transform: "rotate(45deg)", cursor: "pointer" }}
            />
          </div>

          <p style={{ fontSize: "16px", lineHeight: "22px", color: "#979797" }}>Customize the alert template to suit your requirements or use our recommended settings & quickly launch your campaign with optimal setup. If you wish to reset to default and use recommended settings over your changes you can do that using “Set to default” button.</p>

          <FormattingField
            label="Alert Title"
            error={this.props.errors.realtimevisitor_title}
            field={(props) => <Input placeholder="" {...props} style={{ maxWidth: 340 }} />}
            personalizations={[
              { tag: '[alert_count]', title: 'Count' },
              { tag: '[rand(1,100)]', title: 'Random Number in 1...100' },
            ]}
            defaultValue={get(this.props.campaign, "camp_realtimevisitor.title", "")}
            onChange={(val) => this.props.onUpdate("camp_realtimevisitor.title", val)} />

          <FormattingField
            label="Alert Message"
            error={this.props.errors.realtimevisitor_message}
            field={(props) => <Input placeholder="recently booked an appointment" {...props} style={{ maxWidth: 340 }} />}
            personalizations={[
              { tag: '[alert_count]', title: 'Count' },
              { tag: '[rand(1,100)]', title: 'Random Number in 1...100' },
            ]}
            defaultValue={get(this.props.campaign, "camp_realtimevisitor.message", "")}
            onChange={(val) => this.props.onUpdate("camp_realtimevisitor.message", val)} />

          <div className="ant-form-item-control">
            <p className="form-labels">Alert Template</p>
            <Select
              placeholder="Select Template"
              className="template-select"
              style={{ width: 172 }}
              defaultValue={get(this.props.campaign, "camp_realtimevisitor.template", "template2")}
              onChange={(val) => { this.props.onUpdate("camp_realtimevisitor.template", val) }} >
              <Option value="template1"><span><b>Template 1</b><br /><Template1Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template2"><span><b>Template 2</b><br /><Template2Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template3"><span><b>Template 3</b><br /><Template3Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template4"><span><b>Template 4</b><br /><Template4Icon style={{ maxWidth: "100%" }} /></span></Option>
            </Select>
          </div>
        </div>

        <div id="tab2">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3>General Settings</h3>
            <span className="right" style={{ color: "red", cursor: "pointer", fontSize: 14 }} onClick={() => this.props.resetGeneralSettings("camp_realtimevisitor")}>Set To Default</span>
          </div>
          <p style={{ color: "#F5B036", padding: "20px 0 0 0", fontSize: "14px", lineHeight: "22px" }}><Icon type="exclamation-circle" /> We recommend keeping the default values set for optimal performance. You can always switch back to default settings if you do not want to keep your changed settings by clicking “Set To Default”.</p>

          <div className={`ant-form-item-control ${this.props.errors.realtimevisitor_image ? 'has-error' : ''}`}>
            <p className="form-labels">Display Custom Image/GIF
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_realtimevisitor.staticimage.enabled", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_realtimevisitor.staticimage.enabled", checked ? 1 : 0) }} />
            </p>
          </div>
          {parseInt(get(this.props.campaign, "camp_realtimevisitor.staticimage.enabled", 0)) === 1 &&
            <div className="upload-actions">
              {get(this.props.campaign, "camp_realtimevisitor.staticimage.name", "").length > 0 &&
                <div className="upload-action">
                  <img src={`${CDN_URL}/images/campaigns/${get(this.props.campaign, "camp_realtimevisitor.staticimage.name")}`} alt="" style={{ width: '100%', height: '100%' }} />
                </div>
              }
              <div className="upload-action" onClick={() => this.toggleLibraryModal("library")}>
                <p>
                  <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
                  Library
                </p>
              </div>
              <div className="upload-action" onClick={() => this.toggleLibraryModal("upload")}>
                <p>
                  <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                  Upload
                </p>
              </div>
            </div>
          }
          {this.props.errors.realtimevisitor_image && <div className="ant-form-item-control has-error"><div className="ant-form-explain">{this.props.errors.realtimevisitor_image}</div></div>}

          <div className="ant-form-item-control">
            <p className="form-labels">Show alert only once
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_realtimevisitor.showonce", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_realtimevisitor.showonce", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className={`ant-form-item-control ${this.props.errors.realtimevisitor_minvisitors ? 'has-error' : ''}`}>
            <p className="form-labels">Show Alert only when visitors are more than
            <Input
                type="number"
                min={1}
                style={{ maxWidth: 50, marginLeft: 18, verticalAlign: "middle" }}
                value={get(this.props.campaign, "camp_realtimevisitor.min_visitors", 2)}
                onChange={(e) => { this.props.onUpdate("camp_realtimevisitor.min_visitors", e.target.value) }} />
            </p>
            {this.props.errors.realtimevisitor_minvisitors && <div className="ant-form-explain">{this.props.errors.realtimevisitor_minvisitors}</div>}
          </div>
        </div>

        <div id="tab3">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3>Design</h3>
            <span className="right" style={{ color: "red", cursor: "pointer", fontSize: 14 }} onClick={() => this.props.resetDesign("camp_realtimevisitor")}>Set To Default</span>
          </div>
          <DesignSettings settings={get(this.props.campaign, "camp_realtimevisitor.design", {})} onChange={(k, v) => this.props.onUpdate(`camp_realtimevisitor.design.${k}`, v)} />
        </div>

        <div className="ant-form-item-control">
          <Button className="btn-green" onClick={() => this.props.onClickContinue()} size="large">Continue <Icon type="arrow-right" /></Button>
          <br /><br />
        </div>
        <LibraryModal
          {...this.props}
          onCancel={() => this.toggleLibraryModal()}
          tab={this.state.libraryModalTab}
          visible={this.state.libraryModalVisible}
          uploadType="campaigns"
          onChoose={(media) => this.onImageChoose(media)} />
      </div >
    )
  }
}

export default RealtimeVisitorAlert;