import React from 'react';
import { Button, Icon, Tag } from 'antd';
import { Modal } from 'antd';
import './index.scss';

const SwitchAccountModal = (props) => {
  return (
    <Modal
      className="switch-account-modal"
      title={<h3 style={{ fontWeight: "500" }}>Switch Account</h3>}
      visible={props.visible}
      onCancel={props.handleCancel}
      footer={null}
    >
      <div className="switch-account-text">
        <h3 className="title">Select the account you want to switch to:</h3>
        {
          props.accounts.map((account) =>
            <Button key={account.id} onClick={() => { props.history.push(`/switch-account/${account.id}`); }}>
              <span>
                {account.dom_name ? account.dom_name : (props.username.split(' ')[0] + "'s Store")}
                <Tag color="#FF8200">{account.role}</Tag>
              </span>
              <span><Icon type="right" /></span>
            </Button>
          )
        }
      </div>
    </Modal>
  );
};

export default SwitchAccountModal;