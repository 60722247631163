import React from 'react';
import { Alert } from 'antd';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.scss';

const Deactivated = (props) => {
  const { domain } = props.auth;
  let description = "The account you are trying to access has been deactivated. Please contact your account owner to activate the list.";
  if (domain.role === "owner") {
    description = <span>The account you are trying to access has been deactivated. Please go to your <Link to="/manage-accounts">Manage Accounts</Link> page and activate it again.</span>;
  }
  return (
    <div className="account-deactivated">
      <MainHeadingBar title="Account Deactivated" {...props} />
      <Alert
        message="Warning"
        description={description}
        type="warning"
        showIcon
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  };
};
export default connect(mapStateToProps)(Deactivated);