import React, { Component } from 'react';
import { Upload, Icon, message } from 'antd';
const Dragger = Upload.Dragger;

class UploadMedia extends Component {
  render() {
    const { onChoose } = this.props;
    const props = {
      name: 'media',
      accept: 'image/*',
      action: `/library/upload_media`,
      listType: 'picture',
      className: 'upload-list-inline',
      data: {
        domid: this.props.match.params.domid,
        type: this.props.uploadType || ''
      },
      onChange(info) {
        const status = info.file.status;
        if (status === 'done') {
          if (info.file.response.success === 1) {
            message.success(`${info.file.name} file uploaded successfully.`);
            if (onChoose) {
              onChoose(info.file.response.media);
            }
          } else {
            message.error(`${info.file.name}: ${info.file.response.msg}`);
          }
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
      <div className="upload-media">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Click or drag images to this area to upload</p>
        </Dragger>
      </div>
    )
  }

}

export default UploadMedia;