import React from "react";
import './index.scss';
import Pieslice from "../../Partials/Pieslice";

const BrowserWiseStats = (props) => {
    const { data, type } = props;

    return (
        <Pieslice data={data} category='browser' type={type === "visitor" ? "visitors" : "clicks"} />
    );
}

export default BrowserWiseStats;