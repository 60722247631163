import React, { Component } from 'react';
import './index.scss';
import { Card, Button, Row, Alert, Modal, message } from 'antd';
import RecordsTable from '../Partials/RecordsTable';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { connect } from 'react-redux';
import { getRecords, toggleRecordStatus, deleteRecords } from '../../../helpers/ajax/recordsAjax';
import moment from 'moment';
import SearchField from '../Partials/SearchField';

class Records extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        selectedRowKeys: [],
        searchedText: "",
        limit: 10,
        offset: 0,
        records: [],
        pagination: { showSizeChanger: true, total: 200 },
        sortField: '',
        sortOrder: ''
    };

    componentDidMount = () => {
        this.fetch();
    };

    fetch = () => {
        const { domain } = this.props.auth;
        const { limit, offset, sortField, sortOrder, searchedText } = this.state;

        this.setState({
            ...this.state,
            loading: true
        }, () => {
            getRecords(domain.id, limit, offset, sortField, sortOrder, searchedText).then(res => {
                const { pagination } = this.state;
                pagination.total = res.total;

                this.setState({
                    ...this.state,
                    ...res,
                    pagination
                });
            })
        });
    };

    handleSearch = (value) => {
        this.setState({
            ...this.state,
            searchedText: value
        }, () => this.fetch());
    };

    onSelectChange = (selectedRowKeys, actionRealtedValue) => {
        this.setState({
            selectedRowKeys
        }, () => {
            const { domain } = this.props.auth;

            if (typeof (actionRealtedValue) === "number") {
                Modal.confirm({
                    title: 'Are you sure?',
                    content: 'This will change the status of the selected record(s)',
                    okText: 'Confirm',
                    onOk: () => toggleRecordStatus(domain.id, this.state.selectedRowKeys, !actionRealtedValue).then(res => {
                        message.success('Record(s) updated successfully');
                        this.fetch();
                    })
                });
            } else if (typeof (actionRealtedValue === "string") && actionRealtedValue === "delete") {
                Modal.confirm({
                    title: 'Are you sure?',
                    content: 'This will delete the selected record(s)',
                    okText: 'Confirm',
                    onOk: () => deleteRecords(domain.id, this.state.selectedRowKeys).then(res => {
                        message.success('Record(s) deleted successfully');
                        this.setState({
                            selectedRowKeys: []
                        }, () => this.fetch());
                    })
                });
            }
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            ...this.state,
            limit: pagination.pageSize,
            offset: pagination.pageSize * (pagination.current - 1),
            pagination: pager,
            sortField: Object.keys(sorter).length ? sorter.field : '',
            sortOrder: Object.keys(sorter).length ? sorter.order : '',
        }, () => this.fetch());
    };

    render() {
        const { selectedRowKeys, searchedText, records, pagination, loading, error } = this.state;

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={this.state.errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        const dataSource = records.map(record => ({
            key: record.id,
            id: record.id,
            name: record.data.name,
            email: record.data.email,
            country: record.data.country,
            timestamp: moment(record.time).format("MMM DD, YYYY HH:MM"),
            source: record.source,
            status: record.status
        }));

        return (
            <div className="records">
                <MainHeadingBar title="Records" helpText="Here you can manage all the records which you have generated." {...this.props} />
                <Card>
                    <div className="multiple-actions-div">
                        <div className={`buttons-div ${selectedRowKeys.length || 'hidden'}`}>
                            <Button type="danger" onClick={() => this.onSelectChange(selectedRowKeys, "delete")}>Delete</Button>
                            <Button type="danger" onClick={() => this.onSelectChange(selectedRowKeys, 1)}>Disable Selected</Button>
                            <Button type="primary" onClick={() => this.onSelectChange(selectedRowKeys, 0)}>Enable Selected</Button>
                        </div>

                        <div className="search-div">
                            <p className="search-label">Search</p>
                            <SearchField
                                placeholder="Enter name or email"
                                value={searchedText}
                                onChange={val => this.setState({ searchedText: val })}
                                onSearch={(value) => this.handleSearch(value)}
                                style={{ width: "20rem", marginLeft: "15px" }}
                            />
                        </div>
                    </div>

                    <RecordsTable
                        dataSource={dataSource}
                        searchedText={searchedText}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: this.onSelectChange,
                        }}
                        onToggleStatus={(id, status) => this.onSelectChange([id], status)}
                        onDelete={id => this.onSelectChange([id], "delete")}
                        pagination={pagination}
                        handleTableChange={this.handleTableChange}
                        loading={loading}
                    />
                </Card>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(Records);