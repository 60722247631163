import React, { Component } from 'react';
import { switchAccount } from '../../../store/actions/authActions';
import { connect } from 'react-redux';

class SwitchToAccount extends Component {
  componentDidMount() {
    const domid = this.props.match.params.domid;
    this.props.storeSwitched(domid);
  }
  componentDidUpdate() {
    if (this.props.auth.logged_in) {
      if (this.props.auth.user && (this.props.auth.user.user_domains === undefined || Object.keys(this.props.auth.user.user_domains).length === 0)) {
        this.props.history.push(`/onboarding`)
      } else {
        this.props.history.push(`/${this.props.auth.domain.id}`)
      }
    } else {
      this.props.history.push(`/login`)
    }

  }
  render() {
    return (
      <p></p>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeSwitched: (domid) => dispatch(switchAccount(domid))
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SwitchToAccount);