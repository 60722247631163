const initState = {
	authError: null,
	auth: {
		logged_in: localStorage.getItem('logged_in') ? localStorage.getItem('logged_in') : false,
		user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
		domain: localStorage.getItem('domain') ? JSON.parse(localStorage.getItem('domain')) : null,
		plan: localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : null,
		host: localStorage.getItem('host') ? JSON.parse(localStorage.getItem('host')) : {}
	}
}

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'LOGIN_ERROR':
			return {
				...state,
				authError: action.err
			}

		case 'LOGIN_SUCCESS':
			localStorage.setItem('logged_in', true);
			localStorage.setItem('user', JSON.stringify(action.payload.data.user));
			localStorage.setItem('domain', JSON.stringify(action.payload.data.domain));
			localStorage.setItem('plan', JSON.stringify(action.payload.data.plan));
			return {
				...state,
				auth: {
					...state.auth,
					logged_in: true,
					user: action.payload.data.user,
					domain: action.payload.data.domain,
					plan: action.payload.data.plan,
				},
				authError: null
			}

		case 'ACCOUNT_SWITCH_SUCCESSFUL':
			localStorage.setItem('logged_in', true);
			localStorage.setItem('user', JSON.stringify(action.payload.data.user));
			localStorage.setItem('domain', JSON.stringify(action.payload.data.domain));
			localStorage.setItem('plan', JSON.stringify(action.payload.data.plan));
			return {
				...state,
				auth: {
					...state.auth,
					logged_in: true,
					user: action.payload.data.user,
					domain: action.payload.data.domain,
					plan: action.payload.data.plan,
				},
				authError: null
			}

		case 'SIGNOUT_SUCCESS':
			const host = localStorage.getItem('host');
			localStorage.clear();
			localStorage.setItem('host', host);
			return {
				...state,
				auth: {
					...state.auth,
					logged_in: false,
					user: null,
					domain: null,
					plan: null,
				},
				authError: null
			}

		case 'HOST_UPDATED':
			return {
				...state,
				auth: {
					...state.auth,
					host: action.payload.data,
				}
			}

		default:
			return state
	}
};

export default authReducer;