import React, { Component } from 'react';
import './index.scss';
import 'ant-design-pro/dist/ant-design-pro.css';
import moment from 'moment';
import OverallStats from './OverallStats';
import Last30DayStats from './Last30DayStats';
import LastFewCampaigns from './LastFewCampaigns';
import { getDomainAnalytics, getSnapshotStats, getLastThreeCampaigns, getDomainNextSteps } from '../../../helpers/ajax/snapshotAjax';
import { connect } from 'react-redux';
import { Skeleton, Alert, Row, Modal, Card, Button, message } from 'antd';
import { toggleCalloutStatus, toggleCampaignStatus, toggleCampaignArchive } from '../../../helpers/ajax/campaignAjax';
import MainHeadingBar from '../Partials/MainHeadingBar';
import StepsCard from './StepsCard';

class Snapshot extends Component {
    state = {
        activation_status: 0,
        product_updates_available: 1,
        overallStats: {},
        loading: false,
        error: false,
        errorMsg: '',
        stats_start_date: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        stats_end_date: moment().format("YYYY-MM-DD"),
        unique_clicks: [],
        unique_engagements: [],
        campaigns: [],
        campaignsLoaded: false,
        nextsteps: {
            PROFILE: 1,
            PIXEL: 1,
            CAMPAIGN: 1,
        }
    };

    changeState = (newState) => {
        if (newState.hasOwnProperty('start') || newState.hasOwnProperty('end')) {
            this.setState({
                ...this.state,
                stats_start_date: newState.start,
                stats_end_date: newState.end,
            }, () => {
                const { domain } = this.props.auth;
                const { stats_start_date, stats_end_date } = this.state;

                getSnapshotStats(domain.id, stats_start_date, stats_end_date).then(res => {
                    this.setState({
                        ...this.state,
                        ...res
                    });
                });
            });
        } else {
            this.setState({
                ...this.state,
                ...newState
            });
        }
    };

    componentDidMount = () => {
        const { domain } = this.props.auth;
        const { stats_start_date, stats_end_date } = this.state;
        getDomainAnalytics(domain.id).then(res => {
            this.setState({
                ...this.state,
                ...res
            }, () => {
                getDomainNextSteps(domain.id).then(res => {
                    this.setState({
                        ...this.state,
                        ...res
                    }, () => {
                        getSnapshotStats(domain.id, stats_start_date, stats_end_date).then(res => {
                            this.setState({
                                ...this.state,
                                ...res
                            }, () => {
                                getLastThreeCampaigns(domain.id).then(res => {
                                    this.setState({
                                        ...this.state,
                                        ...res,
                                        campaignsLoaded: true
                                    });
                                    window.let_convert.translations = res.translations;
                                })
                            });
                        });
                    });
                });
            });
        });
    };

    toggleCalloutStatus = (key, status, campid) => {
        const { domain } = this.props.auth;

        Modal.confirm({
            title: 'Are you sure?',
            content: 'This will change the status of the selected callout',
            okText: 'Confirm',
            onOk: () => toggleCalloutStatus(domain.id, key, !status, campid).then((res) => {
                if (res.error) {
                    message.error(res.errorMsg)
                } else {
                    getLastThreeCampaigns(domain.id).then(res => {
                        this.setState({
                            ...this.state,
                            ...res
                        });
                    });
                }
            })
        });
    };

    toggleCampaignStatus = (campid, status) => {
        const { domain } = this.props.auth;

        Modal.confirm({
            title: 'Are you sure?',
            content: 'This will change the status of this campaign',
            okText: 'Confirm',
            onOk: () => toggleCampaignStatus(domain.id, campid, !status).then(() => {
                getLastThreeCampaigns(domain.id).then(res => {
                    this.setState({
                        ...this.state,
                        ...res
                    });
                });
            })
        });
    };

    toggleCampaignArchive = (campid, archived) => {
        const { domain } = this.props.auth;

        Modal.confirm({
            title: 'Are you sure?',
            content: `This action will ${archived ? 'un' : ''}archive this campaign`,
            okText: 'Confirm',
            onOk: () => toggleCampaignArchive(domain.id, campid, !archived).then(() => {
                getLastThreeCampaigns(domain.id).then(res => {
                    this.setState({
                        ...this.state,
                        ...res
                    });
                });
            })
        });
    };

    render() {
        const {
            overallStats,
            unique_clicks,
            unique_engagements,
            stats_start_date,
            stats_end_date,
            campaigns,
            loading,
            error,
            errorMsg,
            nextsteps,
            campaignsLoaded
        } = this.state;

        if (loading) {
            return <div style={{ marginBottom: "30px" }}>
                <Card><Skeleton active /></Card>
            </div>
        }

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        return (
            <div className="snapshot">
                <MainHeadingBar title="Your Summary" {...this.props} />
                <OverallStats {...overallStats} />

                {
                    Number(nextsteps.PROFILE) + Number(nextsteps.PIXEL) + Number(nextsteps.CAMPAIGN) < 3
                        ? <StepsCard
                            {...this.props}
                            {...nextsteps}
                            username={this.props.auth.user.user_fname}
                            domid={this.props.auth.domain.id}
                        />
                        : ''
                }

                {
                    campaignsLoaded
                        ? campaigns.length === 0
                            ? <Card className="no-data">
                                <h3 className="heading">Nobody home!</h3>
                                <p className="text">
                                    Install {this.props.auth.host.franchise_name} on your website and create a new campaign to let this graph plot your progress here.
                            </p>
                                <Button type="default"
                                    onClick={() => this.props.history.push(`/${this.props.auth.domain.id}/new_campaign`)}
                                >
                                    Create Campaign
                            </Button>
                            </Card>
                            : <div>
                                <Last30DayStats
                                    unique_clicks={unique_clicks}
                                    unique_engagements={unique_engagements}
                                    start={stats_start_date}
                                    end={stats_end_date}
                                    changeDates={(newDates) => this.changeState(newDates)}
                                />
                                <LastFewCampaigns
                                    campaigns={campaigns}
                                    toggleCalloutStatus={(key, status, campid) => this.toggleCalloutStatus(key, status, campid)}
                                    toggleCampaignStatus={(campid, status) => this.toggleCampaignStatus(campid, status)}
                                    toggleCampaignArchive={(campid, archived) => this.toggleCampaignArchive(campid, archived)}
                                />
                            </div>
                        : ''
                }

            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(Snapshot);