import React, { Component } from "react";
import { Input } from "antd";
import { ChromePicker } from "react-color";

class ColorPicker extends Component {
  state = {
    displayColorPicker: false,
    color: this.props.value ? this.props.value : this.props.defaultValue
  };

  componentDidUpdate(pp) {
    if (pp.value !== this.props.value) {
      this.setState({ color: this.props.value });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    this.setState({ color: color.hex }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.color);
      }
    });
  };

  render() {
    const popover = {
      position: "absolute",
      zIndex: "1001"
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    };
    return (
      <div className="ant-form-item-control">
        <p className="form-labels">{this.props.label}</p>
        <Input
          style={{ width: 100 }}
          value={this.state.color}
          onClick={this.handleClick}
          readOnly
        />
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: 4,
            marginTop: -3,
            width: 36,
            height: 36,
            borderRadius: 3,
            background: this.state.color,
            border: "1px solid #d9d9d9"
          }}
          onClick={this.handleClick}
        />
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
