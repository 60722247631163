import React from 'react';
import { connect } from 'react-redux';
import './index.scss';

const OnboardingFooter = (props) => {
    return (
        <footer className="onboarding-footer" style={{ marginTop: props.marginTop }}>
            <div>
                <a href={props.auth.host.tnc_url} target="_blank" rel="noreferrer noopener">Terms</a>
                <a href={props.auth.host.privacypolicy_url} target="_blank" rel="noreferrer noopener">Privacy</a>
                <a href={props.auth.host.support_url} target="_blank" rel="noreferrer noopener">Help</a>
            </div>
        </footer>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(OnboardingFooter);