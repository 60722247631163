import React from 'react';
import './index.scss';
import { ReactComponent as Tag } from '../../../assets/images/icons/tag.svg';
import { ReactComponent as TagWhite } from '../../../assets/images/icons/tag-white.svg';
import { ReactComponent as Plane } from '../../../assets/images/icons/paper-plane.svg';
import SendToTeammateModal from './SendToTeammateModal';
import SuccessModal from './SuccessModal';

const PixelActions = (props) => {
    const { show, toggleModal } = props;

    return(
        <div className="pixel-actions">
            <div className="action left-action" onClick={() => show('displayPixelInstallOptions')}>
                <Tag />
                <TagWhite className="tag-white" />
                <h3 className="heading">
                    I will install it myself
                </h3>
                <h5 className="sub-heading">
                    Just takes a couple of minutes!
                </h5>
            </div>
            
            <div className="action right-action" onClick={() => toggleModal('sendToTeammateModal')}>
                <Plane />
                <h3 className="heading">
                    Send to a teammate
                </h3>
                <h5 className="sub-heading">
                    We have written the email for you!
                </h5>
            </div>

            <SendToTeammateModal {...props} />
            <SuccessModal {...props} />
        </div>
    );
};

export default PixelActions;