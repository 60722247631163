import Axios from "axios";

export const getLibraryMedia = (domid, limit, offset, type) => {
    return Axios({
        url: '/library/get_media',
        method: 'post',
        data: {
            domid,
            limit,
            offset,
            type
        }
    }).then((res) => {
        return {
            media: res.data.media,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const searchMedia = (type, domid, search, page, per_page, next) => {
    return Axios({
        url: '/library/search_media',
        method: 'post',
        data: {
            type,
            domid,
            search,
            page,
            per_page,
            next
        }
    })
};
export const bulkFetch = (domid, urls) => {
    return Axios({
        url: '/library/fetch_media',
        method: 'post',
        data: {
            domid,
            urls
        }
    }).then((res) => {
        return res;
    })
};

export const deleteLibraryMedia = (domid, ids) => {
    return Axios({
        url: '/library/delete_media',
        method: 'post',
        data: {
            domid,
            ids
        }
    }).then((res) => {
        return res;
    })
};