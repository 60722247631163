import React, { Component } from 'react';
import RecentActivityAlert from '../../../../assets/images/alerts/recent_activity.svg';
import PowerTrailAlert from '../../../../assets/images/alerts/power_trails.svg';
import RealtimeVisitorAlert from '../../../../assets/images/alerts/realtime_usercount.svg';
import UserReviewAlert from '../../../../assets/images/alerts/user_reviews.svg';
import AnnouncementAlert from '../../../../assets/images/alerts/announcements.svg';
import { ReactComponent as Settings } from '../../../../assets/images/icons/setting.svg';
import { Button, Icon, Row, Col, Switch, Alert } from 'antd';
import { get } from 'lodash';
import './index.scss';

class ChooseAlertTab extends Component {

  openCalloutForm(e, type) {
    if (e.target.type !== "button") {
      this.props.toggleCalloutForm(type)
    }
  }

  render() {
    return (
      <div className="campaign-tab choose-alert-tab">
        <div className="tab-header" style={{ marginBottom: 21 }}>
          <h3 className="purple">Choose Alert</h3>
          <Button size="large" className="btn-green right" onClick={() => this.props.nextTab()}>Next <Icon type="arrow-right" /></Button>
        </div>
        <div className="tab-body">
          <p style={{ fontSize: "16px", lineHeight: "22px", color: "#979797", marginBottom: "41px" }}>Choose an alert for continuing <span style={{ fontWeight: 500 }}>campaign alert creation</span> based on your requirement of what kind of alerts you want to show on your website. You can select one right now and add more alert types later to your campaign.</p>

          {this.props.errors.nothingenabled && <div className="ant-form-item-control has-error"><div className="ant-form-explain">{this.props.errors.nothingenabled}</div></div>}

          <Row gutter={53} className="alerts-row">
            <Col xs={12} className="alerts-row-column">
              <div className={`alert-card ${this.props.errors.recentactivityError ? 'has-error' : ''}`} onClick={(e) => this.openCalloutForm(e, "camp_recentactivity")}>
                <p className="alert-card-title">
                  Recent Activity Alert
                  {parseInt(get(this.props.campaign, "camp_recentactivity.enabled", 0)) === 1 && <Icon component={Settings} style={{ position: "absolute", right: "60px", top: "1px", fontSize: "20px" }} />}
                  <Switch
                    checked={parseInt(get(this.props.campaign, "camp_recentactivity.enabled", 0)) === 0 ? false : true}
                    onChange={checked => this.props.onUpdate("camp_recentactivity.enabled", checked ? 1 : 0)}
                    className="right switch-green" />
                  <br /><a href="#/">Customise Alert</a>
                </p>
                {this.props.errors.recentactivityError ? (<Alert message={this.props.errors.recentactivityError} type="error" showIcon />) : (
                  <p className="alert-card-description">Show your visitor's recent activity to influence your visitors into your customers.</p>
                )}
                <p style={{ textAlign: "center" }}><img alt="" src={RecentActivityAlert} style={{ width: "100%" }} /></p>
              </div>
            </Col>

            <Col xs={12} className="alerts-row-column">
              <div className={`alert-card ${this.props.errors.powertrailsError ? 'has-error' : ''}`} onClick={(e) => this.openCalloutForm(e, "camp_powertrails")}>
                <p className="alert-card-title">
                  Power Trail Alert
                  {parseInt(get(this.props.campaign, "camp_powertrails.enabled", 0)) === 1 && <Icon component={Settings} style={{ position: "absolute", right: "60px", top: "1px", fontSize: "20px" }} />}
                  <Switch
                    checked={parseInt(get(this.props.campaign, "camp_powertrails.enabled", 0)) === 0 ? false : true}
                    onChange={checked => this.props.onUpdate("camp_powertrails.enabled", checked ? 1 : 0)}
                    className="right switch-green" />
                  <br /><a href="#/">Customise Alert</a>
                </p>
                {this.props.errors.powertrailsError ? (<Alert message={this.props.errors.powertrailsError} type="error" showIcon />) : (
                  <p className="alert-card-description">Show conversions count on your website to increase the authority of your website.</p>
                )}
                <p style={{ textAlign: "center" }}><img alt="" src={PowerTrailAlert} style={{ width: "100%" }} /></p>
              </div>
            </Col>
          </Row>
          <Row gutter={53} className="alerts-row">
            <Col xs={12} className="alerts-row-column">
              <div className={`alert-card ${this.props.errors.realtimevisitorError ? 'has-error' : ''}`} onClick={(e) => this.openCalloutForm(e, "camp_realtimevisitor")}>
                <p className="alert-card-title">
                  Real Time Visitors Alert
                  {parseInt(get(this.props.campaign, "camp_realtimevisitor.enabled", 0)) === 1 && <Icon component={Settings} style={{ position: "absolute", right: "60px", top: "1px", fontSize: "20px" }} />}
                  <Switch
                    checked={parseInt(get(this.props.campaign, "camp_realtimevisitor.enabled", 0)) === 0 ? false : true}
                    onChange={checked => this.props.onUpdate("camp_realtimevisitor.enabled", checked ? 1 : 0)}
                    className="right switch-green" />
                  <br /><a href="#/">Customise Alert</a>
                </p>
                {this.props.errors.realtimevisitorError ? (<Alert message={this.props.errors.realtimevisitorError} type="error" showIcon />) : (
                  <p className="alert-card-description">Show Real time visitor count on your website to create the sense of fomo in your visitors.</p>
                )}
                <p style={{ textAlign: "center" }}><img alt="" src={RealtimeVisitorAlert} style={{ width: "100%" }} /></p>
              </div>
            </Col>

            <Col xs={12} className="alerts-row-column">
              <div className={`alert-card ${this.props.errors.userreviewsError ? 'has-error' : ''}`} onClick={(e) => this.openCalloutForm(e, "camp_userreviews")}>
                <p className="alert-card-title">
                  User Reviews Alert
                  {parseInt(get(this.props.campaign, "camp_userreviews.enabled", 0)) === 1 && <Icon component={Settings} style={{ position: "absolute", right: "60px", top: "1px", fontSize: "20px" }} />}
                  <Switch
                    checked={parseInt(get(this.props.campaign, "camp_userreviews.enabled", 0)) === 0 ? false : true}
                    onChange={checked => this.props.onUpdate("camp_userreviews.enabled", checked ? 1 : 0)}
                    className="right switch-green" />
                  <br /><a href="#/">Customise Alert</a>
                </p>
                {this.props.errors.userreviewsError ? (<Alert message={this.props.errors.userreviewsError} type="error" showIcon />) : (
                  <p className="alert-card-description">Put up reviews to show the ultimate social proof of authenticity to dive more conversions.</p>
                )}
                <p style={{ textAlign: "center" }}><img alt="" src={UserReviewAlert} style={{ width: "100%" }} /></p>
              </div>
            </Col>
          </Row>

          <Row gutter={53} className="alerts-row">
            <Col xs={12} className="alerts-row-column">
              <div className="alert-card coming-soon" onClick={(e) => this.openCalloutForm(e, "camp_announcements")}>
                <p className="alert-card-title">
                  Announcements
                  <Switch
                    className="right switch-green" />
                </p>
                <p className="alert-card-description">Show up important updates, exciting offers or any major announcements to connect with your audience.</p>
                <p style={{ textAlign: "center" }}><img alt="" src={AnnouncementAlert} style={{ width: "100%" }} /></p>
              </div>
            </Col>

            <Col xs={12}></Col>
          </Row>
          <Row>
            <Col xs={24} style={{ position: "relative" }}>
              <Button className="btn-green" onClick={() => this.props.nextTab()} size="large">Next <Icon type="arrow-right" /></Button>
              {!this.props.edit && <Button className="btn-red" size="large" style={{ width: 169, margin: "0 0 0 15px", "fontSize": 16, "height": 40, maxHeight: "unset" }} onClick={() => this.props.draft()} loading={this.props.saving}>Save Draft</Button>}
            </Col>
          </Row>
        </div>
      </div>

    )
  }
}

export default ChooseAlertTab;