import axios from "axios";

export const forgotPassword = (email) => {
  return axios({
    url: '/login/forgot_password',
    method: 'post',
    data: {
      email
    }
  })
}

export const resetPassword = (hash, password) => {
  return axios({
    url: '/login/reset_password',
    method: 'post',
    data: {
      hash,
      password
    }
  })
}