import React, { Component } from 'react';
import { Icon, Card, Button } from 'antd';
import RecentActivityAlert from '../RecentActivityAlert';
import PowerTrailsAlert from '../PowerTrailsAlert';
import RealtimeVisitorAlert from '../RealtimeVisitorAlert';
import UserReviewsAlert from '../UserReviewsAlert';
import CampaignPreview from '../../../Partials/CampaignCard/CampaignPreview';
import { ReactComponent as LivePreviewIcon } from '../../../../../assets/images/callouts/live-preview-arrow.svg';
import { isEqual, debounce } from 'lodash';
import './index.scss'

class CalloutFormContainer extends Component {
  state = {
    activeTab: "tab1",
  }

  scrollTo(key) {
    this.setState({
      activeTab: key,
    }, () => {
      const obj = document.querySelector(`#${key}`);
      if (obj) {
        const parentElement = document.querySelector(`#${key}`).parentElement;
        if (parentElement) {
          parentElement.scrollTo(0, obj.offsetTop);
        }
      }
    })
  }

  componentWillReceiveProps(props) {
    if (!isEqual(props, this.props)) {
      this.setState();
    }
    if (!props.visible) {
      setTimeout(() => {
        this.scrollTo("tab1");
      }, 1250)
    }
  }

  onScroll = debounce((e) => {
    let currentTab = "";
    const scrollPosition = e.target.scrollTop;
    const windowHeight = document.querySelector(".callout-form-container .tab-body>div").clientHeight;
    const tab1Position = document.querySelector("#tab1").offsetTop;
    const tab2Position = document.querySelector("#tab2").offsetTop;
    const tab3Position = document.querySelector("#tab3").offsetTop;
    if (scrollPosition + windowHeight / 2 > tab1Position) {
      currentTab = "tab1"
    }
    if (scrollPosition + windowHeight / 2 > tab2Position) {
      currentTab = "tab2"
    }
    if (scrollPosition + windowHeight / 2 > tab3Position) {
      currentTab = "tab3"
    }
    if (currentTab !== "") {
      this.setState({
        activeTab: currentTab
      })
    }
  }, 50)

  componentDidMount() {
    document.querySelector(".callout-form-container .tab-body").addEventListener("scroll", this.onScroll, true)
  }

  componentWillUnmount() {
    document.querySelector(".callout-form-container .tab-body").removeEventListener("scroll", this.onScroll);
  }

  onClickContinue() {
    this.props.onUpdate(`${this.props.type}.enabled`, 1);
    this.props.toggle();
  }

  render() {
    return (
      <React.Fragment>
        <div className={`preview-sidebar ${this.props.visible ? 'visible' : ''}`}>
          <div className="live-preview">
            <div style={{ display: "block", "margin": "auto" }}><LivePreviewIcon /><span className="circle-ripple"></span></div>
            {this.props.visible && this.props.calloutType && <CampaignPreview camp={{ ...this.props.campaign, _id: { $id: 0 } }} calloutType={this.props.calloutType} translations={this.props.translations} />}
          </div>
        </div>
        <div className={`callout-form-container ${this.props.visible ? 'visible' : ''}`}>
          <Card>
            <div className="ant-tabs ant-tabs-left ant-tabs-vertical ant-tabs-line">
              <div role="tablist" className="ant-tabs-bar ant-tabs-left-bar" tabIndex="0">
                <div className="ant-tabs-nav-container">
                  <span unselectable="unselectable" className="ant-tabs-tab-prev ant-tabs-tab-btn-disabled">
                    <span className="ant-tabs-tab-prev-icon"></span>
                  </span>
                  <span unselectable="unselectable" className="ant-tabs-tab-next ant-tabs-tab-btn-disabled">
                    <span className="ant-tabs-tab-next-icon"></span>
                  </span>
                  <div className="ant-tabs-nav-wrap">
                    <div className="ant-tabs-nav-scroll">
                      <div className="ant-tabs-nav ant-tabs-nav-animated">
                        <div>
                          <div
                            role="tab"
                            aria-disabled="false"
                            aria-selected="true"
                            onClick={() => this.scrollTo("tab1")}
                            className={`ant-tabs-tab ${this.state.activeTab === "tab1" ? 'ant-tabs-tab-active' : ''}`}>Create Alert
                          </div>
                          <div
                            role="tab"
                            aria-disabled="false"
                            aria-selected="false"
                            onClick={() => this.scrollTo("tab2")}
                            className={`ant-tabs-tab ${this.state.activeTab === "tab2" ? 'ant-tabs-tab-active' : ''}`}>General Settings
                          </div>
                          <div
                            role="tab"
                            aria-disabled="false"
                            aria-selected="false"
                            onClick={() => this.scrollTo("tab3")}
                            className={`ant-tabs-tab ${this.state.activeTab === "tab3" ? 'ant-tabs-tab-active' : ''}`}>Design
                          </div>
                        </div>
                        <Button
                          className="btn-green"
                          size="large"
                          onClick={() => this.props.toggle()}
                          style={{ display: "block", margin: "auto", marginTop: 30 }}>Continue <Icon type="arrow-right" /></Button>

                        {!this.props.edit && <Button className="btn-red" size="large" style={{ width: 169, display: "block", margin: "auto", marginTop: 30 }} onClick={() => this.props.draft()} loading={this.props.saving}>Save Draft</Button>}
                        <div className="ant-tabs-ink-bar ant-tabs-ink-bar-animated">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-tabs-content ant-tabs-left-content">
                <div className="tab-body">
                  {this.props.type === "camp_recentactivity" && <RecentActivityAlert {...this.props} onClickContinue={() => this.onClickContinue()} />}
                  {this.props.type === "camp_powertrails" && <PowerTrailsAlert {...this.props} onClickContinue={() => this.onClickContinue()} />}
                  {this.props.type === "camp_realtimevisitor" && <RealtimeVisitorAlert {...this.props} onClickContinue={() => this.onClickContinue()} />}
                  {this.props.type === "camp_userreviews" && <UserReviewsAlert {...this.props} onClickContinue={() => this.onClickContinue()} />}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </React.Fragment >
    );
  }
}

export default CalloutFormContainer;