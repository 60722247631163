import React from 'react';
import './index.scss';
import { Divider, message, Tooltip, Icon } from 'antd';
import { connect } from 'react-redux';
import PixelInstallOptions from '../../Partials/PixelInstallOptions';
import SendCodeToTeammate from '../../Partials/SendCodeToTeammate';

const Pixel = (props) => {
    const { domain } = props.auth;

    return (
        <div className="pixel-settings">
            <h3 className="heading">Add Pixel <Tooltip title="Here you can find the pixel."><Icon type="info-circle" style={{ fontSize: "1.5rem" }} /></Tooltip></h3>

            <Divider />

            <PixelInstallOptions {...domain} />

            <p className="disclaimer">OR</p>

            <p className="disclaimer">
                Send {props.auth.host.franchise_name} code to teammate! We will send an email to ask your teammate to help you get started with {props.auth.host.franchise_name}.
            </p>

            <SendCodeToTeammate {...domain} onSuccess={() => message.success('Email sent to the developer!')} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(Pixel);