import Axios from "axios";

export const addDomain = (dom_name, dom_domain) => {
    return Axios({
        url: '/onboarding/add_domain',
        method: 'post',
        data: {
            dom_name,
            dom_domain
        }
    }).then((res) => {
        return res.data;
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const saveSignupQuestions = (data) => {
    return Axios({
        url: '/user/save_signup_questions',
        method: 'post',
        data: {
            ...data
        }
    }).then((res) => {
        return res.data;
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};