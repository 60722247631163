import Axios from "axios";

export const getReviews = (domid, limit, offset, sortField, sortOrder, searchedText, pool) => {
    return Axios({
        url: '/reviews/get_reviews',
        method: 'post',
        data: {
            domid,
            limit,
            offset,
            sortField,
            sortOrder,
            searchedText,
            pool,
        }
    }).then((res) => {
        return {
            reviews: res.data.reviews,
            total: res.data.total,
            pools: res.data.pools,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const addReview = (domid, review) => {
    return Axios({
        url: '/reviews/add_review',
        method: 'post',
        data: {
            domid,
            ...review
        }
    })
};

export const deleteReviews = (domid, ids) => {
    return Axios({
        url: '/reviews/delete_reviews',
        method: 'post',
        data: {
            domid,
            ids
        }
    }).then((res) => {
        return res;
    })
};

export const getReviewPools = (domid, limit, offset, sortField, sortOrder, searchedText) => {
    return Axios({
        url: '/reviews/get_review_pools',
        method: 'post',
        data: {
            domid,
            limit,
            offset,
            sortField,
            sortOrder,
            searchedText,
        }
    }).then((res) => {
        return {
            pools: res.data.pools,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const deleteReviewPools = (domid, ids) => {
    return Axios({
        url: '/reviews/delete_review_pools',
        method: 'post',
        data: {
            domid,
            ids
        }
    }).then((res) => {
        return res;
    })
};

export const getReviewSources = (domid, limit, offset, sortField, sortOrder, searchedText) => {
    return Axios({
        url: '/reviews/get_reviewsources',
        method: 'post',
        data: {
            domid,
            limit,
            offset,
            sortField,
            sortOrder,
            searchedText,
        }
    }).then((res) => {
        return {
            sources: res.data.sources,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const deleteReviewSources = (domid, ids) => {
    return Axios({
        url: '/reviews/delete_review_sources',
        method: 'post',
        data: {
            domid,
            ids
        }
    }).then((res) => {
        return res;
    })
};

export const addGoogleReviewSource = (domid, source) => {
    return Axios({
        url: '/reviews/add_google_review_source',
        method: 'post',
        data: {
            domid,
            source
        }
    })
};