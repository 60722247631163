import React, { Component } from 'react';
import './index.scss';
import { Button, Divider, Icon, Table, Skeleton, Row, Alert, Tag, Tooltip } from 'antd';
import { ReactComponent as EditReviewIcon } from '../../../../assets/images/icons/editReview.svg'
import { connect } from 'react-redux';
import { getTranslations } from '../../../../helpers/ajax/settingsAjax';
import TranslationsModal from './TranslationsModal';

class Translations extends Component {
    state = {
        error: false,
        errorMsg: '',
        loading: true,
        translations: [],
        total: 0,
        pagination: { showSizeChanger: true, total: 200 },
        modalVisible: false,
        modalType: 'add_modal',
        translationToEdit: {}
    };

    fetch = () => {
        getTranslations(this.props.auth.domain.id).then(res => {
            const { pagination } = this.state;
            pagination.total = res.total;

            this.setState({
                ...this.state,
                ...res,
                pagination
            });
        });
    };

    componentDidMount = () => {
        this.fetch();
    }

    changeState = (newState) => {
        this.setState({
            ...this.state,
            ...newState
        });
    };

    render() {
        const { error, errorMsg, loading, translations, pagination, modalType, modalVisible, translationToEdit } = this.state;

        if (loading) {
            return <div style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </div>
        }

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0),
            render: text => <p className="translation-name">{text}</p>
        }, {
            title: 'Right-To-Left Support',
            dataIndex: 'rtl',
            key: 'rtl',
            render: text => (
                text === "1" || text === 1
                    ? <Tag color="#36C626" className="role-tag">Enabled</Tag>
                    : <Tag color="#F84B3D" className="role-tag">Disabled</Tag>
            )
        }, {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: (text, record) => <div className="actions-div">
                <Tooltip title="Edit Translation Set"><Button type="default" className="edit-btn" onClick={() => {
                    this.changeState({
                        modalVisible: true,
                        modalType: 'edit_modal',
                        translationToEdit: translations.filter(translation => translation._id.$id === text)[0]
                    })
                }}><Icon component={EditReviewIcon} /></Button></Tooltip>
            </div>
        }];
        const dataSource = translations.map(translation => ({
            key: translation._id.$id,
            id: translation._id.$id,
            name: translation.translation_name,
            rtl: translation.translation_rtl,
        }));

        return (
            <div className="translations">
                <h3 className="heading">
                    <span className="text">Translations <Tooltip title="Here you can manage the translation. "><Icon type="info-circle" style={{ fontSize: "1.5rem" }} /></Tooltip></span>

                    <Button
                        className="add-translation-btn"
                        onClick={() => { this.changeState({ modalVisible: true, modalType: 'add_modal' }) }}
                    >
                        <Icon type="plus" size="large" />Add New Translation Set
                    </Button>
                </h3>

                <Divider />

                <p className="disclaimer">
                    Translation sets allow you to preset nomenclature in your desired language for some root keywords in the platform that are used to make alerts showing up on your website, e.g. “few minutes ago,” “few hours ago,” etc.
                </p>

                <Table
                    className="records-table"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                />

                <TranslationsModal
                    modalType={modalType}
                    modalVisible={modalVisible}
                    closeModal={() => {
                        this.changeState({ modalVisible: false });
                        this.fetch();
                    }}
                    domid={this.props.auth.domain.id}
                    translation={translationToEdit}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(Translations);