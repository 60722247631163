import moment from 'moment';
import DataSet from "@antv/data-set";

export const modifyHourwiseData = (data) => {
    return data.map(thisData => { 
        return {
            ...thisData,
            value: Number(thisData.value),
            hour: thisData.hour >= 10
                ? `${thisData.hour}:00 - ${1 + thisData.hour}:00`
                : `0${thisData.hour}:00 - 0${1 + thisData.hour}:00`
        };
    });
};

export const modifyDaywiseData = (data) => {
    return data.map(thisData => { 
        return {
            ...thisData,
            value: Number(thisData.value),
            date: moment(thisData.date).format("MMM DD, YYYY")
        };
    });
};

export const modifyBrowserWiseData = (data) => {
    const dv = new DataSet.DataView();

    dv.source(data).transform({
        type: "percent",
        field: "count",
        dimension: "item",
        as: "percent"
    });

    return dv;
};