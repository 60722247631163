import React from 'react';
import './index.scss';
import { Divider, Row, Col } from 'antd';

const SubscriptionDetails = (props) => {
    const { plan } = props;

    return (
        <div className="billing-subscription-details">
            <h3 className="heading">Subscription Details</h3>

            <Divider />

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Plan Name</h5>
                    <p className="value">{plan.plan_name}</p>
                </Col>

                <Col xs={24} md={{ span: 12 }}>
                    <h5 className="label">Billing Cycle</h5>
                    <p className="value">Monthly</p>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Number Of Codes Activated</h5>
                    <p className="value">1</p>
                </Col>

                <Col xs={24} md={{ span: 12 }}>
                    <h5 className="label">Total Views Enabled</h5>
                    <p className="value">100,000</p>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Number Of Accounts Enabled</h5>
                    <p className="value">1</p>
                </Col>
            </Row>
        </div>
    );
};

export default SubscriptionDetails;