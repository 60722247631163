import React, { Component } from 'react';
import './index.scss';
import { Modal, Form, Input, Button, message } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { saveIntegrationCreds } from '../../../../../helpers/ajax/integrationsAjax';

class DripForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { domid, closeModal } = this.props;

                saveIntegrationCreds(domid, 'drip', values).then(res => {
                    if (!res.error) {
                        message.success('Settings saved successfully!');
                        closeModal();
                    } else {
                        message.error('Could not save settings!');
                    }
                });
            }
        });
    }
    
    render() {
        const { modalType, settings } = this.props;
        const { getFieldDecorator } = this.props.form;
        
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    <Input.Group>
                        <p className="label">Account Id</p>
                        {getFieldDecorator('account_id', {
                            rules: [{ required: true, message: 'Account Id is required!' }],
                            initialValue: (modalType === 'edit_modal') ? settings.account_id : ''
                        })(
                            <Input placeholder="Enter Account Id" />
                        )}
                    </Input.Group>
                </Form.Item>

                <Form.Item>
                    <Input.Group>
                        <p className="label">API Key</p>
                        {getFieldDecorator('api_key', {
                            rules: [{ required: true, message: 'API Key is required!' }],
                            initialValue: (modalType === 'edit_modal') ? settings.api_key : ''
                        })(
                            <Input placeholder="Enter API Key" />
                        )}
                    </Input.Group>
                </Form.Item>

                <Form.Item>
                    <Button className="submit-button" type="default" size="large" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
const WrappedDripForm = Form.create({ name: 'add_user' })(DripForm);

class DripModal extends Component {
    render() {
        const { visible, modalType, closeModal, domid, settings } = this.props;

        return(
            <Modal
                className="integration-modal"
                visible={visible}
                onOk={closeModal}
                title={
                    <p className="ant-modal-title custom">
                        Drip Integration Settings
                        <CloseIcon onClick={closeModal} />
                    </p>
                }
                footer={null}
                width={700}
                zIndex={1000}
            >
                <WrappedDripForm modalType={modalType} closeModal={closeModal} domid={domid} settings={settings} />
            </Modal>
        );
    }
}

export default DripModal;