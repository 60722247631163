import React, { Component } from 'react';
import { Input, Select, Icon, Switch, Button, Alert } from 'antd';
import { get } from 'lodash';
import DesignSettings from '../DesignSettings';
import { CDN_URL } from '../../../../../config';
import { ReactComponent as Template1Icon } from '../../../../../assets/images/callout_templates/template1.svg';
import { ReactComponent as Template2Icon } from '../../../../../assets/images/callout_templates/template2.svg';
import { ReactComponent as Template3Icon } from '../../../../../assets/images/callout_templates/template3.svg';
import { ReactComponent as Template5Icon } from '../../../../../assets/images/callout_templates/template5.svg';
const { Option } = Select;

class UserReviewsAlert extends Component {

  render() {
    return (
      <div className="user-review-alert" style={{ position: "relative" }}>
        <div id="tab1">
          <div className="tab-header" style={{ marginBottom: 21 }}>
            <h3 className="purple">Customize Alert</h3>
            <Icon
              type="plus"
              className="right"
              onClick={() => this.props.toggle()}
              style={{ fontSize: "24px", transform: "rotate(45deg)", cursor: "pointer" }}
            />
          </div>

          <p id="tab1" style={{ fontSize: "16px", lineHeight: "22px", color: "#979797" }}>Customize the alert template to suit your requirements or use our recommended settings & quickly launch your campaign with optimal setup. If you wish to reset to default and use recommended settings over your changes you can do that using “Set to default” button.</p>

          <div className="ant-form-item-control">
            <p className="form-labels">Alert Template</p>
            <Select
              placeholder="Select Template"
              className="template-select"
              style={{ width: 172 }}
              defaultValue={get(this.props.campaign, "camp_userreviews.template", "template2")}
              onChange={(val) => { this.props.onUpdate("camp_userreviews.template", val) }} >
              <Option value="template1"><span><b>Template 1</b><br /><Template1Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template2"><span><b>Template 2</b><br /><Template2Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template3"><span><b>Template 3</b><br /><Template3Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template5"><span><b>Template 4</b><br /><Template5Icon style={{ maxWidth: "100%" }} /></span></Option>
            </Select>
          </div>

          {this.props.errors.userreviews_review && <Alert message={this.props.errors.userreviews_review} style={{ margin: 0 }} type="error" showIcon />}

          <div className="ant-form-item-control">
            <p className="form-labels">Reviews</p>
            <Select
              placeholder="Select Reviews"
              mode="multiple"
              className="user-review-alert-review-select"
              style={{ width: "100%" }}
              defaultValue={get(this.props.campaign, "camp_userreviews.reviews", [])}
              onChange={(val) => {
                const id = val[val.length - 1];
                let changes = [];
                changes.push({ path: "camp_userreviews.reviews", value: val, });
                let review = this.props.reviews.filter((r) => r._id["$id"] === id);
                if (review.length) {
                  review = review[0];
                }
                if (review) {
                  changes.push({ path: "camp_userreviews.previewdata.image", value: review.review_image });
                  changes.push({ path: "camp_userreviews.previewdata.name", value: review.review_name });
                  changes.push({ path: "camp_userreviews.previewdata.stars", value: review.review_stars });
                  changes.push({ path: "camp_userreviews.previewdata.text", value: review.review_reviewtext });
                }
                this.props.onUpdate(changes)
              }} >
              {this.props.reviews.map((r) =>
                <Option value={r._id["$id"]} key={r._id["$id"]}>
                  <div className="review-preview">
                    <div className="review-previewimage" style={{
                      background: `url(${CDN_URL}/reviews/${r.review_image})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: 'no-repeat'
                    }}></div>
                    <div className="review-content">
                      <p className="reviewer_name">{r.review_name}</p>
                      <p className="review_text">
                        {r.review_reviewtext}
                      </p>
                      <p className="review_stars">{Array(isNaN(parseInt(r.review_stars)) ? 0 : parseInt(r.review_stars)).fill(0).map((_, i) => <Icon key={i} type="star" theme="filled" style={{ color: "#FFBA1F" }} />)}</p>
                    </div>
                  </div>
                </Option>
              )}
            </Select>
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels">Pools</p>
            <Select
              placeholder="Select Review Pools"
              mode="multiple"
              style={{ width: "100%" }}
              defaultValue={get(this.props.campaign, "camp_userreviews.pools", [])}
              onChange={(val) => {
                const pool = val[val.length - 1];
                let changes = [];
                changes.push({ path: "camp_userreviews.pools", value: val });
                let review = this.props.reviews.filter((r) => r.review_pools.indexOf(pool) !== -1);
                if (review.length) {
                  review = review[0];
                }
                if (review) {
                  changes.push({ path: "camp_userreviews.previewdata.image", value: review.review_image });
                  changes.push({ path: "camp_userreviews.previewdata.name", value: review.review_name });
                  changes.push({ path: "camp_userreviews.previewdata.stars", value: review.review_stars });
                  changes.push({ path: "camp_userreviews.previewdata.text", value: review.review_reviewtext });
                }
                this.props.onUpdate(changes)
              }} >
              {this.props.pools.map((r) => <Option value={r._id} key={r._id}>{r._id}</Option>)}
            </Select>
          </div>
        </div>

        <div id="tab2">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3 className="purple">General Settings</h3>
            <span className="right" style={{ color: "red", cursor: "pointer", fontSize: 14 }} onClick={() => this.props.resetGeneralSettings("camp_userreviews")}>Set To Default</span>
          </div>
          <p style={{ color: "#F5B036", padding: "20px 0 0 0", fontSize: "14px", lineHeight: "22px" }}><Icon type="exclamation-circle" /> We recommend keeping the default values set for optimal performance. You can always switch back to default settings if you do not want to keep your changed settings by clicking “Set To Default”.</p>


          <div className="ant-form-item-control">
            <p className="form-labels">Show alert only once
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_userreviews.showonce", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_userreviews.showonce", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className={`ant-form-item-control ${this.props.errors.userreviews_click ? 'has-error' : ''}`}>
            <p className="form-labels">What happens when visitor clicks the alert</p>
            <Select
              style={{ width: 172 }}
              value={get(this.props.campaign, "camp_userreviews.click.type", "no_action")}
              onChange={(val) => { this.props.onUpdate("camp_userreviews.click.type", val) }} >
              <Option value="no_action" selected="">No Action</Option>
              <Option value="redirect">Redirect in same tab</Option>
              <Option value="redirect_newtab">Redirect in new tab</Option>
              <Option value="jscode" data-label="JS Code">Trigger JS Code</Option>
              <Option value="yt_embed" data-label="Youtube Embed Code">Youtube Embed</Option>
            </Select>

            {
              (get(this.props.campaign, "camp_userreviews.click.type", "no_action") === "redirect" ||
                get(this.props.campaign, "camp_userreviews.click.type", "no_action") === "redirect_newtab") &&
              <Input
                placeholder="Enter Redirect URL"
                type="url"
                defaultValue={get(this.props.campaign, "camp_userreviews.click.url", "")}
                onChange={(e) => { this.props.onUpdate("camp_userreviews.click.url", e.target.value) }}
                style={{ maxWidth: 340, marginLeft: 20 }}
              />
            }

            {
              (get(this.props.campaign, "camp_userreviews.click.type", "no_action") === "jscode" ||
                get(this.props.campaign, "camp_userreviews.click.type", "no_action") === "yt_embed") &&
              <Input.TextArea
                placeholder=""
                type="url"
                defaultValue={get(this.props.campaign, "camp_userreviews.click.code", "")}
                onChange={(e) => { this.props.onUpdate("camp_userreviews.click.code", e.target.value) }}
                style={{ maxWidth: 340, marginLeft: 20, verticalAlign: 'top' }}
              />
            }
            {this.props.errors.userreviews_click && <div className="ant-form-explain">{this.props.errors.userreviews_click}</div>}
          </div>
        </div>

        <div id="tab3">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3 className="purple">Design</h3>
            <span className="right" style={{ color: "red", cursor: "pointer", fontSize: 14 }} onClick={() => this.props.resetDesign("camp_userreviews")}>Set To Default</span>
          </div>
          <DesignSettings settings={get(this.props.campaign, "camp_userreviews.design", {})} onChange={(k, v) => this.props.onUpdate(`camp_userreviews.design.${k}`, v)} />
        </div>

        <div className="ant-form-item-control">
          <Button className="btn-green" onClick={() => this.props.onClickContinue()} size="large">Continue <Icon type="arrow-right" /></Button>
          <br /><br />
        </div>
      </div >
    )
  }
}

export default UserReviewsAlert;