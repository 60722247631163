import React, { Component } from "react";
import MainHeadingBar from "../../Dashboard/Partials/MainHeadingBar";
import CreateCampaignTab from "./CreateCampaignTab";
import ChooseAlertTab from "./ChooseAlertTab";
import CampaignSettingsTab from "./CampaignSettingsTab";
import CalloutFormContainer from "./Callouts/CalloutFormContainer";
import { Card, Tabs, Skeleton, Alert, message, Modal } from "antd";
import { set, debounce, merge } from "lodash";
import { newCampaign, saveCampaign } from "../../../helpers/ajax/campaignAjax";
import { refreshList } from "../../../helpers/ajax/integrationsAjax";
import "./index.scss";
const { TabPane } = Tabs;
const { confirm } = Modal;

class NewCampaign extends Component {
  state = {
    calloutForm: {
      visible: false,
      type: ""
    },
    campaign: {
      camp_name: "",
      camp_domid: this.props.match.params.domid,
      camp_source: "AUTO_RECORD",
      camp_displayurl: [
        {
          type: "CONTAINS",
          data: ""
        }
      ],
      camp_translation: {
        enabled: 0,
        language: ""
      },
      camp_realtimevisitor: {
        enabled: 0,
        min_visitors: 2,
        title: "<h>[alert_count] Marketers</h>",
        message: " are viewing right now",
        template: "template2",
        staticimage: {
          enabled: "1",
          name: "81c60bbef15a02235548a270bf543d2d.gif"
        },
        design: {
          background: "#ffffff",
          title: "#03658c",
          text: "#000",
          highlightbg: "#bef0f0",
          highlighttext: "#005cbe",
          animation: "zoomIn",
          position: "bottom-left",
          font: {
            family: "Quicksand",
            url:
              '<link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet">'
          }
        }
      },
      camp_recentactivity: {
        enabled: 1,
        template: "template2",
        title: "[first_name]([place])",
        message: "Recently purchased.",
        settings: {
          onlymaps: 1,
          staticimage: {
            enabled: 1,
            image: "7b9605333f3587faaa1e006c11500806.gif"
          },
          hideownconversions: 1,
          minconversions: 2,
          hideanonymous: 0,
          showonce: 1,
          ignorecsvfirstrow: 0,
          hideoldalerts: {
            data: 7,
            unit: "days"
          },
          click: {
            type: "no_action",
            url: "",
            code: ""
          }
        },
        design: {
          background: "#ffffff",
          title: "#03658c",
          text: "#000",
          highlightbg: "#bef0f0",
          highlighttext: "#005cbe",
          animation: "zoomIn",
          position: "bottom-left",
          font: {
            family: "Quicksand",
            url:
              '<link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet">'
          }
        }
      },
      camp_userreviews: {
        enabled: 0,
        template: "template1",
        showonce: 1,
        reviews: [],
        pools: [],
        click: {
          type: "no_action",
          url: "",
          code: ""
        },
        design: {
          background: "#ffffff",
          title: "#03658c",
          text: "#000",
          highlightbg: "#bef0f0",
          highlighttext: "#005cbe",
          animation: "zoomIn",
          position: "bottom-left",
          font: {
            family: "Quicksand",
            url:
              '<link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet">'
          }
        }
      },
      camp_powertrails: {
        enabled: 0,
        template: "template2",
        showonce: 0,
        mintrails: 2,
        type: "conversion",
        window: {
          data: 7,
          unit: "days"
        },
        title: "<h>[alert_count]</h> People",
        message: "purchased in last [alert_window]",
        staticimage: {
          enabled: 1,
          name: "3d0cfa05ebad2cb706154c1399ccfa39.gif"
        },
        design: {
          background: "#ffffff",
          title: "#03658c",
          text: "#000",
          highlightbg: "#bef0f0",
          highlighttext: "#005cbe",
          animation: "zoomIn",
          position: "bottom-left",
          font: {
            family: "Quicksand",
            url:
              '<link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet">'
          }
        }
      },
      camp_settings: {
        hidemobile: 0,
        timing: {
          startdelay: 1,
          displayduration: 7,
          displaydelay: 4
        },
        repeat: {
          type: "REFRESH"
        }
      },
      camp_calloutdesign: {
        background: "#ffffff",
        title: "#03658c",
        text: "#000",
        highlightbg: "#bef0f0",
        highlighttext: "#005cbe",
        animation: "zoomIn",
        position: "bottom-left",
        font: {
          family: "Quicksand",
          url:
            '<link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet">'
        }
      }
    },
    reviews: [],
    pools: [],
    translations: [],
    integrations: {},
    loading: true,
    saving: false,
    error: false,
    errorMsg: "",
    activeTabKey:
      this.props.match.params.page &&
      ["1", "2", "3"].indexOf(this.props.match.params.page) !== -1
        ? this.props.match.params.page
        : "1",
    validation_errors: {}
  };

  componentDidMount() {
    newCampaign(this.props.match.params.domid, this.props.match.params.campid)
      .then(res => {
        if (res.data.success === 1) {
          let updates = {
            loading: false,
            reviews: res.data.reviews,
            campaign: this.state.campaign,
            pools: res.data.pools,
            integrations: res.data.integrations,
            translations: res.data.translations,
            error: false,
            errorMsg: ""
          };
          window.let_convert.translations = res.data.default_translations;
          Object.values(res.data.translations).forEach(
            t =>
              (window.let_convert.translations[t._id["$id"]] =
                t.translation_data)
          );
          if (res.data.campaign) {
            updates.campaign = merge(this.state.campaign, res.data.campaign);
            delete updates.campaign.camp_manualrecords;
            if (!this.props.edit) {
              updates.campaign.camp_name = updates.campaign.camp_name + " Copy";
              updates.campaign.camp_recentactivity.enabled = 0;
              updates.campaign.camp_powertrails.enabled = 0;
              updates.campaign.camp_userreviews.enabled = 0;
              updates.campaign.camp_realtimevisitor.enabled = 0;
              delete updates.campaign._id;
            }
          }
          if (
            updates.campaign.camp_displayurl &&
            updates.campaign.camp_displayurl.length
          ) {
            updates.campaign.camp_displayurl = updates.campaign.camp_displayurl.map(
              (u, i) => ({ ...u, id: Date.now() + i })
            );
          }
          if (
            updates.campaign.camp_excludeurl &&
            updates.campaign.camp_excludeurl.length
          ) {
            updates.campaign.camp_excludeurl = updates.campaign.camp_excludeurl.map(
              (u, i) => ({ ...u, id: Date.now() + i })
            );
          }
          if (
            updates.campaign.camp_referrerurl &&
            updates.campaign.camp_referrerurl.length
          ) {
            updates.campaign.camp_referrerurl = updates.campaign.camp_referrerurl.map(
              (u, i) => ({ ...u, id: Date.now() + i })
            );
          }
          if (
            updates.campaign.camp_captureurl &&
            updates.campaign.camp_captureurl.length
          ) {
            updates.campaign.camp_captureurl = updates.campaign.camp_captureurl.map(
              (u, i) => ({ ...u, id: Date.now() + i })
            );
          }
          this.setState(updates, () => {
            try {
              const unsavedCampaignData = JSON.parse(
                localStorage.getItem("unsavedCampaignData")
              );
              let unsavedCampaign = false;
              if (
                unsavedCampaignData["camp_domid"] ===
                  this.props.match.params.domid ||
                unsavedCampaignData["camp_domid"]["$id"] ===
                  this.props.match.params.domid
              ) {
                unsavedCampaign = true;
              }
              if (unsavedCampaign) {
                this.setState(
                  {
                    loading: true
                  },
                  () => {
                    const that = this;
                    confirm({
                      title: "Recover Unsaved Campaign?",
                      content:
                        "We detected some unsaved changes while you were last creating a campaign. Do you want to recover those changes?",
                      onOk() {
                        that.setState({
                          campaign: unsavedCampaignData,
                          loading: false
                        });
                      },
                      onCancel() {
                        localStorage.removeItem("unsavedCampaignData");
                        that.setState({ loading: false });
                      }
                    });
                  }
                );
              } else {
                localStorage.removeItem("unsavedCampaignData");
              }
            } catch {
              localStorage.removeItem("unsavedCampaignData");
            }
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: res.data.msg
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          loading: false,
          error: true,
          errorMsg: e.message
        });
      });
  }

  toggleCalloutForm(type) {
    this.setState({
      calloutForm: {
        type: this.state.visible ? "" : type,
        visible: !this.state.calloutForm.visible
      }
    });
  }

  refreshIntegration(integration) {
    let integrations = this.state.integrations;
    Object.keys(this.state.integrations).forEach(i => {
      if (i === integration) {
        integrations[i].loading = true;
      }
    });
    this.setState(
      {
        integrations
      },
      () => {
        refreshList(integration, this.props.match.params.domid)
          .then(res => {
            this.setState({
              integrations: {
                ...this.state.integrations,
                [integration]: {
                  lists: res.data.lists
                    ? res.data.lists
                    : this.state.integrations[integration].lists
                }
              }
            });
          })
          .catch(() => {
            this.setState({
              integrations: {
                ...this.state.integrations,
                [integration]: {
                  lists: this.state.integrations[integration].lists
                }
              }
            });
          });
      }
    );
  }

  validateCampaign() {
    const camp = this.state.campaign;
    let errors = {};
    let tab1Error = false,
      tab2Error = false,
      tab3Error = false,
      recentactivityError = false,
      powertrailsError = false,
      userreviewsError = false,
      realtimevisitorError = false;

    if (camp.camp_name.trim().length === 0) {
      errors.camp_name = "This is a required field.";
      tab1Error = true;
    }
    if (camp.camp_displayurl.length === 0) {
      errors.camp_name = "Atleast one display url is required.";
      tab1Error = true;
    } else {
      camp.camp_displayurl.forEach(d => {
        if (d.data.trim().length === 0) {
          errors.camp_displayurl =
            "Please enter a valid match condition for all fields.";
          tab1Error = true;
        }
      });
    }
    if (
      camp.camp_excludeurl &&
      camp.camp_excludeurl.constructor === Array &&
      camp.camp_excludeurl.length > 0
    ) {
      camp.camp_excludeurl.forEach(d => {
        if (d.data.trim().length === 0) {
          errors.camp_excludeurl =
            "Please enter a valid match condition for all fields.";
          tab1Error = true;
        }
      });
    }
    if (
      camp.camp_referrerurl &&
      camp.camp_referrerurl.constructor === Array &&
      camp.camp_referrerurl.length > 0
    ) {
      camp.camp_referrerurl.forEach(d => {
        if (d.data.trim().length === 0) {
          errors.camp_referrerurl =
            "Please enter a valid match condition for all fields.";
          tab1Error = true;
        }
      });
    }
    if (
      camp.camp_captureurl &&
      camp.camp_captureurl.constructor === Array &&
      camp.camp_captureurl.length > 0
    ) {
      camp.camp_captureurl.forEach(d => {
        if (d.data.trim().length === 0) {
          errors.ca_captureurl =
            "Please enter a valid match condition for all fields.";
          tab1Error = true;
        }
      });
    }
    if (
      parseInt(camp.camp_recentactivity.enabled) === 0 &&
      parseInt(camp.camp_powertrails.enabled) === 0 &&
      parseInt(camp.camp_userreviews.enabled) === 0 &&
      parseInt(camp.camp_realtimevisitor.enabled) === 0
    ) {
      errors.nothingenabled = "Please enable atleast one alert";
      tab2Error = true;
    }
    if (camp.camp_settings.repeat.type === "DURATION") {
      if (camp.camp_settings.repeat.duration === "") {
        errors.camp_repeat = "Please enter a valid repeat duration";
        tab3Error = true;
      }
    }
    if (parseInt(camp.camp_recentactivity.enabled) === 1) {
      if (camp.camp_recentactivity.title.trim().length === 0) {
        errors.recentactivity_title = "Please enter a valid title";
        recentactivityError = true;
      }
      if (camp.camp_recentactivity.message.trim().length === 0) {
        errors.recentactivity_message = "Please enter a valid message";
        recentactivityError = true;
      }
      if (camp.camp_recentactivity.settings.minconversions === "") {
        errors.recentactivity_minconversions = "Please enter a valid number";
        recentactivityError = true;
      }
      if (camp.camp_recentactivity.settings.hideoldalerts === "") {
        errors.recentactivity_hideoldalerts = "Please enter a valid number";
        recentactivityError = true;
      }
      if (
        parseInt(camp.camp_recentactivity.settings.staticimage.enabled) === 1
      ) {
        if (
          camp.camp_recentactivity.settings.staticimage.image &&
          camp.camp_recentactivity.settings.staticimage.image.trim() === ""
        ) {
          errors.recentactivity_image = "Please select an image";
          recentactivityError = true;
        }
      }
      if (
        camp.camp_recentactivity.settings.click.type === "redirect" ||
        camp.camp_recentactivity.settings.click.type === "redirect_newtab"
      ) {
        if (camp.camp_recentactivity.settings.click.url.trim() === "") {
          errors.recentactivity_click = "Please enter a valid url";
          recentactivityError = true;
        }
      }
      if (
        camp.camp_recentactivity.settings.click.type === "jscode" ||
        camp.camp_recentactivity.settings.click.type === "yt_embed"
      ) {
        if (camp.camp_recentactivity.settings.click.code.trim() === "") {
          errors.recentactivity_click = "Please enter a valid code";
          recentactivityError = true;
        }
      }
      if (
        camp.camp_integrations &&
        Object.keys(camp.camp_integrations).length
      ) {
        Object.keys(camp.camp_integrations).forEach(i => {
          if (
            parseInt(camp.camp_integrations[i].on) === 1 &&
            (camp.camp_integrations[i].list === undefined ||
              camp.camp_integrations[i].list === "")
          ) {
            errors.recentactivity_integrations =
              "Please select a list for all the enabled integrations";
            recentactivityError = true;
          }
        });
      }
    }
    if (parseInt(camp.camp_powertrails.enabled) === 1) {
      if (camp.camp_powertrails.title.trim().length === 0) {
        errors.powertrails_title = "Please enter a valid title";
        powertrailsError = true;
      }
      if (camp.camp_powertrails.message.trim().length === 0) {
        errors.powertrails_message = "Please enter a valid message";
        powertrailsError = true;
      }
      if (camp.camp_powertrails.window.data === "") {
        errors.powertrails_window = "Please enter a valid number";
        powertrailsError = true;
      }
      if (camp.camp_powertrails.mintrails === "") {
        errors.powertrails_mintrails = "Please enter a valid number";
        powertrailsError = true;
      }
      if (parseInt(camp.camp_powertrails.staticimage.enabled) === 1) {
        if (camp.camp_powertrails.staticimage.name.trim() === "") {
          errors.powertrails_image = "Please select an image";
          powertrailsError = true;
        }
      }
    }
    if (parseInt(camp.camp_realtimevisitor.enabled) === 1) {
      if (camp.camp_realtimevisitor.title.trim().length === 0) {
        errors.realtimevisitor_title = "Please enter a valid title";
        realtimevisitorError = true;
      }
      if (camp.camp_realtimevisitor.message.trim().length === 0) {
        errors.realtimevisitor_message = "Please enter a valid message";
        realtimevisitorError = true;
      }
      if (camp.camp_realtimevisitor.min_visitors === "") {
        errors.realtimevisitor_minvisitors = "Please enter a valid number";
        realtimevisitorError = true;
      }
      if (parseInt(camp.camp_realtimevisitor.staticimage.enabled) === 1) {
        if (camp.camp_realtimevisitor.staticimage.name.trim() === "") {
          errors.realtimevisitor_image = "Please select an image";
          realtimevisitorError = true;
        }
      }
    }
    if (parseInt(camp.camp_userreviews.enabled) === 1) {
      if (
        (camp.camp_userreviews.reviews === undefined ||
          camp.camp_userreviews.reviews.length === 0) &&
        (camp.camp_userreviews.pools === undefined ||
          camp.camp_userreviews.pools.length === 0)
      ) {
        errors.userreviews_review = "Please select atleast one review or pool";
        userreviewsError = true;
      }
      if (
        camp.camp_userreviews.click.type === "redirect" ||
        camp.camp_userreviews.click.type === "redirect_newtab"
      ) {
        if (camp.camp_userreviews.click.url.trim() === "") {
          errors.userreviews_click = "Please enter a valid url";
          userreviewsError = true;
        }
      }
      if (
        camp.camp_userreviews.click.type === "jscode" ||
        camp.camp_userreviews.click.type === "yt_embed"
      ) {
        if (camp.camp_userreviews.click.code.trim() === "") {
          errors.userreviews_click = "Please enter a valid code";
          userreviewsError = true;
        }
      }
    }
    if (Object.keys(errors).length) {
      message.error(
        "Please fix the errors highlighted in red and try saving again!",
        5
      );
      let updates = {};
      if (tab1Error) {
        updates.activeTabKey = "1";
      } else if (tab2Error) {
        updates.activeTabKey = "2";
      } else if (recentactivityError) {
        errors.recentactivityError = (
          <span>
            Please <b>Click Here</b> to fix the errors for this alert!
          </span>
        );
        updates.activeTabKey = "2";
      } else if (powertrailsError) {
        errors.powertrailsError = (
          <span>
            Please <b>Click Here</b> to fix the errors for this alert!
          </span>
        );
        updates.activeTabKey = "2";
      } else if (userreviewsError) {
        errors.userreviewsError = (
          <span>
            Please <b>Click Here</b> to fix the errors for this alert!
          </span>
        );
        updates.activeTabKey = "2";
      } else if (realtimevisitorError) {
        errors.realtimevisitorError = (
          <span>
            Please <b>Click Here</b> to fix the errors for this alert!
          </span>
        );
        updates.activeTabKey = "2";
      } else if (tab3Error) {
        updates.activeTabKey = "3";
      }
      this.setState({
        validation_errors: errors,
        ...updates
      });
      return false;
    } else {
      this.setState({
        validation_errors: {}
      });
    }
    return true;
  }

  validateAndSaveCampaign() {
    if (this.validateCampaign()) {
      this.setState({ saving: true }, () => {
        saveCampaign(
          { ...this.state.campaign, draft: "0" },
          this.props.match.params.domid
        )
          .then(res => {
            this.setState({ saving: false });
            if (res.data.success === 1) {
              message.success("Campaign Created Successfully!");
              localStorage.removeItem("unsavedCampaignData");
              this.props.history.push(
                `/${this.props.match.params.domid}/active_campaigns`
              );
            }
          })
          .catch(e => {
            message.error(e.message);
            this.setState({ saving: false });
          });
      });
    }
  }

  saveDraft() {
    this.setState({ saving: true }, () => {
      saveCampaign(
        { ...this.state.campaign, draft: "1" },
        this.props.match.params.domid
      )
        .then(res => {
          this.setState({ saving: false });
          if (res.data.success === 1) {
            message.success("Draft Created Successfully!");
            localStorage.removeItem("unsavedCampaignData");
            this.props.history.push(
              `/${this.props.match.params.domid}/draft_campaigns`
            );
          }
        })
        .catch(e => {
          message.error(e.message);
          this.setState({ saving: false });
        });
    });
  }

  onUpdate = debounce((path, value) => {
    let c = this.state.campaign;
    if (path.constructor === Array) {
      path.forEach(pv => (c = set(c, pv.path, pv.value)));
    } else {
      c = set(this.state.campaign, path, value);
    }
    let allNoProtocol = true;
    let allURLs = [];

    if (c.camp_displayurl && c.camp_displayurl.length) {
      allURLs = [...allURLs, ...c.camp_displayurl];
    }
    if (c.camp_captureurl && c.camp_captureurl.length) {
      allURLs = [...allURLs, ...c.camp_captureurl];
    }
    if (c.camp_excludeurl && c.camp_excludeurl.length) {
      allURLs = [...allURLs, ...c.camp_excludeurl];
    }
    allURLs.forEach(u => {
      if (u.data.match(/^https?:\/\//)) {
        allNoProtocol = false;
      }
    });
    if (!allNoProtocol) {
      c.camp_settings.absoluteurl = 1;
    }

    this.setState(
      {
        campaign: c
      },
      () => {
        if (this.props.match.params.campid === undefined) {
          localStorage.setItem("unsavedCampaignData", JSON.stringify(c));
        }
      }
    );
  }, 30);

  resetGeneralSettings = type => {
    const defaultSettings = {
      camp_realtimevisitor: [
        {
          path: "camp_realtimevisitor.min_visitors",
          value: 2
        },
        {
          path: "camp_realtimevisitor.staticimage",
          value: {
            enabled: "1",
            name: "81c60bbef15a02235548a270bf543d2d.gif"
          }
        }
      ],
      camp_recentactivity: [
        {
          path: "camp_recentactivity.settings",
          value: {
            onlymaps: 0,
            staticimage: {
              enabled: 1,
              image: "7b9605333f3587faaa1e006c11500806.gif"
            },
            hideownconversions: 1,
            minconversions: 2,
            hideanonymous: 0,
            showonce: 1,
            ignorecsvfirstrow: 0,
            hideoldalerts: {
              data: 7,
              unit: "days"
            },
            click: {
              type: "no_action",
              url: "",
              code: ""
            }
          }
        }
      ],
      camp_userreviews: [
        {
          path: "camp_userreviews.showonce",
          value: 1
        },
        {
          path: "camp_userreviews.click",
          value: {
            type: "no_action",
            url: "",
            code: ""
          }
        }
      ],
      camp_powertrails: [
        {
          path: "camp_powertrails.showonce",
          value: 0
        },
        {
          path: "camp_powertrails.mintrails",
          value: 2
        },
        {
          path: "camp_powertrails.staticimage",
          value: {
            enabled: 1,
            name: "3d0cfa05ebad2cb706154c1399ccfa39.gif"
          }
        }
      ]
    };
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to reset the settings?",
      onOk: () => {
        this.onUpdate(defaultSettings[type]);
      },
      onCancel: () => {}
    });
  };

  resetDesign = type => {
    const defaultSettings = {
      camp_realtimevisitor: {
        background: "#ffffff",
        title: "#03658c",
        text: "#060707",
        highlightbg: "#defdfd",
        highlighttext: "#193d3d",
        animation: "fadeInLeft",
        position: "bottom-left",
        font: {
          family: "Roboto",
          url:
            '<link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet">'
        }
      },
      camp_recentactivity: {
        background: "#ffffff",
        title: "#03658c",
        text: "#000",
        highlightbg: "#bef0f0",
        highlighttext: "#005cbe",
        animation: "fadeInUp",
        position: "bottom-left",
        font: {
          family: "Roboto",
          url:
            '<link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet">'
        }
      },
      camp_userreviews: {
        background: "#ffffff",
        title: "#151517",
        text: "#000",
        highlightbg: "#e0fafa",
        highlighttext: "#131a1d",
        animation: "fadeInLeft",
        position: "bottom-left",
        font: {
          family: "Roboto",
          url:
            '<link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet">'
        }
      },
      camp_powertrails: {
        background: "#ffffff",
        title: "#000000",
        text: "#151515",
        highlightbg: "#dcfafa",
        highlighttext: "#000000",
        animation: "fadeInUp",
        position: "bottom-left",
        font: {
          family: "Roboto",
          url:
            '<link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet">'
        }
      }
    };
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to reset the settings?",
      onOk: () => {
        this.onUpdate(`${type}.design`, defaultSettings[type]);
      },
      onCancel: () => {}
    });
  };

  goToNextTab() {
    let nextTab = "";
    const currentTab = this.state.activeTabKey;
    if (currentTab === "1") {
      nextTab = "2";
    } else if (currentTab === "2") {
      nextTab = "3";
    }
    if (nextTab.length) {
      this.setState({ activeTabKey: nextTab });
    }
  }

  render() {
    const PageWrapper = children => (
      <div className="new-campaign">
        <MainHeadingBar
          title={this.props.edit ? "Edit Campaign" : "New Campaign"}
          hideCTA
        />
        {children}
        <CalloutFormContainer
          {...this.props}
          resetDesign={type => this.resetDesign(type)}
          resetGeneralSettings={type => this.resetGeneralSettings(type)}
          edit={this.props.edit}
          draft={() => this.saveDraft()}
          saving={this.state.saving}
          toggle={() => this.toggleCalloutForm()}
          visible={this.state.calloutForm.visible}
          campaign={this.state.campaign}
          calloutType={this.state.calloutForm.type}
          type={this.state.calloutForm.type}
          reviews={this.state.reviews}
          pools={this.state.pools}
          integrations={this.state.integrations}
          refreshIntegration={i => this.refreshIntegration(i)}
          errors={this.state.validation_errors}
          onUpdate={(path, val) => this.onUpdate(path, val)}
        />
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(<Skeleton active />);
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Alert
          message="Error"
          description={this.state.errorMsg}
          type="error"
          showIcon
        />
      );
    }

    return PageWrapper(
      <Card>
        <Tabs
          tabPosition="left"
          activeKey={this.state.activeTabKey}
          onTabClick={tab => this.setState({ activeTabKey: tab })}
        >
          <TabPane tab="Create Campaign" key="1">
            <CreateCampaignTab
              errors={this.state.validation_errors}
              campaign={this.state.campaign}
              onUpdate={(path, val) => this.onUpdate(path, val)}
              nextTab={() => this.goToNextTab()}
              translations={this.state.translations}
              edit={this.props.edit}
              draft={() => this.saveDraft()}
              saving={this.state.saving}
            />
          </TabPane>
          <TabPane tab="Choose Alert" key="2">
            <ChooseAlertTab
              errors={this.state.validation_errors}
              toggleCalloutForm={type => this.toggleCalloutForm(type)}
              campaign={this.state.campaign}
              onUpdate={(path, val) => this.onUpdate(path, val)}
              nextTab={() => this.goToNextTab()}
              edit={this.props.edit}
              draft={() => this.saveDraft()}
              saving={this.state.saving}
            />
          </TabPane>
          <TabPane tab="Settings" key="3">
            <CampaignSettingsTab
              errors={this.state.validation_errors}
              campaign={this.state.campaign}
              onUpdate={(path, val) => this.onUpdate(path, val)}
              save={() => this.validateAndSaveCampaign()}
              edit={this.props.edit}
              draft={() => this.saveDraft()}
              saving={this.state.saving}
            />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default NewCampaign;
