import React from 'react';
import './index.scss';
import { Divider, Tooltip, Icon } from 'antd';
import { connect } from 'react-redux';
import PixelVerificationAction from '../../Partials/PixelVerificationAction';

const VerifyPixel = (props) => {
    const { domain } = props.auth;
    return (
        <div className="verify-pixel-settings">
            <h3 className="heading">Verify Pixel <Tooltip title="Here you can verify the pixel."><Icon type="info-circle" style={{ fontSize: "1.5rem" }} /></Tooltip></h3>

            <Divider />

            <PixelVerificationAction {...domain} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(VerifyPixel);