import React, { Component } from 'react';
import { signOut } from '../../../store/actions/authActions';
import { connect } from 'react-redux';

class SwitchToAccount extends Component {
  componentDidMount() {
    this.props.signOutRequest();
  }
  render() {
    return (
      <p></p>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutRequest: (domid) => dispatch(signOut())
  }
}


export default connect(null, mapDispatchToProps)(SwitchToAccount);