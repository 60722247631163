import React from 'react';
import './index.scss';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { ReactComponent as Plane } from '../../../../assets/images/icons/paper-plane.svg';
import SendCodeToTeammate from '../../../Dashboard/Partials/SendCodeToTeammate';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';

const SendToTeammateModal = (props) => {
    const { domain, visibleModal, toggleModal } = props;

    return (
        <Modal
            visible={visibleModal === "sendToTeammateModal"}
            closable={false}
            footer={null}
            className="send-to-teammate-modal"
            width={536}
        >
            <Close className="close-modal" onClick={() => toggleModal("")} />
            <Plane />

            <h3 className="title">Send {props.auth.host.franchise_name} code to a teammate!</h3>
            <p className="description">
                We will send an email to ask your teammate to help you get started with {props.auth.host.franchise_name}.
            </p>

            <SendCodeToTeammate {...domain} onSuccess={() => toggleModal("successModal")} />
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(SendToTeammateModal);