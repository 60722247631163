import React, { Component } from 'react';
import { Table, Tooltip, Button, Icon, Modal, Input, DatePicker } from 'antd';
import { ReactComponent as EditReviewIcon } from '../../../../../assets/images/icons/editReview.svg';
import { ReactComponent as DeleteReviewIcon } from '../../../../../assets/images/icons/delete.svg';
import moment from 'moment';
import { isEqual } from 'lodash';
import './index.scss';

const { confirm } = Modal;

class ManualRecords extends Component {
  state = {
    paging: {
      current: 1,
      pageSize: 5
    },
    addModal: {
      visible: false,
      data: {
        name: '',
        email: '',
        location: '',
        date: null
      }
    },
    errors: {}
  }

  componentWillReceiveProps(props) {
    if (!isEqual(props.records, this.props.records)) {
      this.setState();
    }
  }

  deleteRecord(index) {
    const that = this;
    confirm({
      title: 'Are you sure?',
      content: 'Are you sure you want to delete this record?',
      onOk() {
        that.props.onUpdate(
          [...that.props.records.slice(0, index), ...that.props.records.slice(index + 1)].map((r) => ({ ...r, key: undefined }))
        )
      }
    })
  }

  editReview(index) {
    let record = this.props.records[index];
    record.id = index;
    if (!moment(record.date).isValid()) {
      record.date = '';
    }
    this.setState({
      addModal: {
        ...this.state.addModal,
        visible: true,
        data: {
          ...this.state.addModal.data,
          ...record
        }
      }
    })
  }

  goToPage({ current }) {
    this.setState({
      paging: { ...this.state.paging, current }
    });
  }

  toggleAddModal() {
    this.setState({
      addModal: {
        ...this.state.addModal,
        visible: !this.state.addModal.visible
      }
    })
  }

  setField(k, v) {
    this.setState({
      addModal: {
        ...this.state.addModal,
        data: {
          ...this.state.addModal.data,
          [k]: v
        }
      }
    })
  }

  resetModal() {
    this.setState({
      addModal: {
        ...this.state.addModal,
        data: {
          name: '',
          email: '',
          location: '',
          date: null
        }
      }
    })
  }

  addRecord() {
    const record = this.state.addModal.data;
    let errors = {};
    if (record.name.trim().length === 0) {
      errors["name"] = "This is a required field";
    }
    // if (record.email.trim().length === 0) {
    //   errors["email"] = "This is a required field";
    // }
    const re = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!re.test(record.email.toLowerCase())) {
      errors["email"] = "Please enter a valid email address";
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors
      })
    } else {
      this.setState({
        errors: {}
      }, () => {
        if (record.id !== undefined) {
          this.props.onUpdate([...this.props.records.slice(0, record.id), { ...record, id: undefined }, ...this.props.records.slice(record.id + 1)]);
        } else {
          this.props.onUpdate([record, ...this.props.records]);
        }
        this.toggleAddModal();
      })
    }
  }

  render() {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
    }, {
      title: 'Location',
      dataIndex: 'location',
    }, {
      title: 'Email',
      dataIndex: 'email',
    }, {
      title: 'Date',
      dataIndex: 'date',
    }, {
      title: 'Actions',
      dataIndex: 'id',
      width: '200px',
      render: (_, record, index) => <div className="actions-div">
        <Tooltip placement="top" title="Edit"><Button className="edit-btn" onClick={() => this.editReview(index)}> <Icon component={EditReviewIcon} /></Button></Tooltip>
        <Tooltip placement="top" title="Delete"><Button type="danger" className="delete-btn" onClick={() => this.deleteRecord(index)}><Icon component={DeleteReviewIcon} /></Button></Tooltip>
      </div>
    }];

    return (
      <div className="manual-records">
        <p className="form-labels">Manually Add Records <Button className="btn-red" onClick={() => this.toggleAddModal()}><Icon type="plus" /> Add</Button></p>
        <Table
          className="manual-records-table"
          dataSource={this.props.records.map((i, index) => ({ ...i, key: index }))}
          pagination={{ ...this.state.paging, total: this.props.records.length }}
          columns={columns}
          onChange={(page) => this.goToPage(page)}
          scroll={{ x: "100%" }}
        />

        <Modal
          className="add-manual-record-modal"
          visible={this.state.addModal.visible}
          title="Add New Record"
          afterClose={() => this.resetModal()}
          onCancel={() => this.toggleAddModal()}
          footer={null}
        >
          <div className="add-record-form">
            <div className={`ant-form-item-control ${this.state.errors.name ? 'has-error' : ''}`}>
              <label>Name</label>
              <Input placeholder="Enter Name" value={this.state.addModal.data.name} onChange={e => this.setField("name", e.target.value)} />
              {this.state.errors.name && <div className="ant-form-explain">{this.state.errors.name}</div>}
            </div>

            <div className={`ant-form-item-control ${this.state.errors.email ? 'has-error' : ''}`}>
              <label>Email</label>
              <Input placeholder="Enter Email" value={this.state.addModal.data.email} onChange={e => this.setField("email", e.target.value)} />
              {this.state.errors.email && <div className="ant-form-explain">{this.state.errors.email}</div>}
            </div>

            <div className="ant-form-item-control">
              <label>Location</label>
              <Input placeholder="Enter Location" value={this.state.addModal.data.location} onChange={e => this.setField("location", e.target.value)} />
            </div>

            <div className="ant-form-item-control">
              <label>Date</label>
              <DatePicker placeholder="Select Date" format="YYYY-MM-DD HH:mm:ss" style={{ width: "100%" }} value={this.state.addModal.data.date ? moment(this.state.addModal.data.date) : null} onChange={(date, dateString) => this.setField("date", dateString)} allowClear />
            </div>

            <div className="ant-form-item-control" style={{ marginTop: "2.35rem" }}>
              <Button className="submit-btn" size="large" onClick={() => this.addRecord()}>Add Record</Button>
            </div>
          </div>
        </Modal>

      </div>
    )
  }
}

export default ManualRecords
