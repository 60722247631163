import Axios from "axios";

export const getIntegrationsByDomId = (domid) => {
    return Axios({
        url: '/integrations/get_integrationsByDomId',
        method: 'post',
        data: {
            domid
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                ...res.data.integrations,
                loading: false,
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.msg,
                loading: false,
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const saveIntegrationCreds = (domid, type, data) => {
    return Axios({
        url: '/integrations/save_integration_creds',
        method: 'post',
        data: {
            domid,
            type,
            ...data
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false,
                loading: false,
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.msg,
                loading: false,
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
}

export const refreshList = (type, domid) => {
    return Axios({
        url: '/integrations/refresh_list',
        method: 'post',
        data: {
            type,
            domid,
        }
    })
};