import Axios from "axios";

export const getRecords = (domid, limit, offset, sortField, sortOrder, searchedText, campid) => {
    return Axios({
        url: '/records/',
        method: 'post',
        data: {
            domid,
            limit,
            offset,
            sortField,
            sortOrder,
            searchedText,
            campid
        }
    }).then((res) => {
        return {
            records: res.data.records,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const toggleRecordStatus = (domid, selectedRowKeys, status) => {
    return Axios({
        url: '/records/toggle_record_status',
        method: 'post',
        data: {
            domid,
            selectedRowKeys,
            status
        }
    }).then((res) => {
        if (res.data.success === 1) {
            return {
                error: false,
                errorMsg: '',
                loading: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.message,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const deleteRecords = (domid, selectedRowKeys) => {
    return Axios({
        url: '/records/delete_records',
        method: 'post',
        data: {
            domid,
            selectedRowKeys
        }
    }).then((res) => {
        if (res.data.success === 1) {
            return {
                error: false,
                errorMsg: '',
                loading: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.message,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};