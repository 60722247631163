import React from 'react';
import { connect } from 'react-redux';
import { CDN_URL } from '../../../../config';
import './index.scss';

const Footer = (props) => {
  return (
    <footer className="app-footer">
      <ul>
        <li><img src={`${CDN_URL}/global/images/${props.auth.host.logos.full_logo}`} alt="app footer logo" /></li>
        <li><a href={props.auth.host.support_url}>Support</a></li>
        <li><a href={props.auth.host.tnc_url}>Terms of Service</a></li>
        <li><a href={props.auth.host.privacypolicy_url}>Privacy Policy</a></li>
      </ul>
    </footer>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}
export default connect(mapStateToProps)(Footer);