import React from 'react';
import './index.scss';
import { Tabs } from 'antd';
import AccountSettings from './AccountSettings';
import UserSettings from './UserSettings';
import Translations from './Translations';
import Pixel from './Pixel';
import VerifyPixel from './VerifyPixel';
import Integrations from './Integrations';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

const Settings = (props) => {
    const role = props.auth.user.user_domains[props.auth.domain.id]['role'];
    return (
        <div className="settings">
            <MainHeadingBar title="Settings" {...props} />
            <Tabs tabPosition="left" defaultActiveKey="1">
                <TabPane tab="Account Settings" key="1">
                    <AccountSettings />
                </TabPane>
                <TabPane tab="Integrations" key="2">
                    <Integrations {...props} />
                </TabPane>
                {role === "owner" && <TabPane tab="Users" key="3">
                    <UserSettings />
                </TabPane>}
                <TabPane tab="Pixel" key="4">
                    <Pixel />
                </TabPane>
                <TabPane tab="Verify Pixel" key="5">
                    <VerifyPixel />
                </TabPane>
                <TabPane tab="Translations" key="6">
                    <Translations />
                </TabPane>
            </Tabs>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(Settings);