import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { CDN_URL } from '../../../config';
import './index.scss';

class LoginPageContainer extends Component {
    render() {
        const { auth } = this.props;
        if (auth.logged_in) return <Redirect to={`/switch-account`} />
        return (
            <div className="login-page-container">
                <div className="login-container">
                    <React.Fragment>
                        <Row type="flex" justify="center">
                            <img className="logo" src={`${CDN_URL}/global/images/${this.props.auth.host.logos.full_logo}`} alt="Logo" />
                        </Row>
                        {this.props.children}
                        <div className="login-page-footer">
                            <Row type="flex" justify="center">
                                <ul className="footer-links">
                                    <li><a href={this.props.auth.host.tnc_url}>Terms</a></li>
                                    <li><a href={this.props.auth.host.privacypolicy_url}>Privacy</a></li>
                                    <li><a href={this.props.auth.host.support_url}>Help</a></li>
                                </ul>
                            </Row>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}

export default connect(mapStateToProps)(LoginPageContainer);
