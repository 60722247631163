import React from 'react';
import { Layout, Menu, Icon, Dropdown, Avatar, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CDN_URL } from '../../../../config';
import { ReactComponent as InfoCircle } from '../../../../assets/images/icons/info-cirlce-o.svg';
import './index.scss';

const { Header } = Layout;
const { Item } = Menu;

const Navbar = (props) => {

    const { user, domain } = props;
    const { user_fname } = user;
    const userDropdownMenu = (
        <Menu className="navbar-user-dropdown">
            <Item key="account" onClick={() => props.toggleSwitchAccountModal()}>
                <div className="current-account-details">
                    <span className="account-name">{(domain && domain.dom_name) ? domain.dom_name : (user_fname.split(" ")[0] + "'s Domain")}</span>
                    <Tag color="#FF8200">{user.user_domains[domain.id]['role']}</Tag>
                </div>
                <p className="switch-account-label"><b>Switch Account</b></p>
            </Item>
            {/* <Item key="plan">Plan: {props.plan.plan_name}</Item> */}
            <Item key="profile_settings"><Link to="/settings">Profile Settings <Icon type="user" /></Link></Item>
            {/* <Item key="billing"><Link to={`/${domain.id}/billing`}>Billing <Icon type="dollar" /></Link></Item> */}
            {/* <Item key="facebook_group"><a href="https://www.facebook.com/groups/conversionhackersclub" target="_blank" rel="noreferrer noopener">Facebook Group <Icon type="facebook" /></a></Item> */}
            {/* <Item key="add_account"><Link to='/onboarding'>Add Account <Icon type="usergroup-add" /></Link></Item> */}
            {/* <Item key="manage_accounts"><Link to='/manage-accounts'>Manage Accounts <Icon type="usergroup-add" /></Link></Item> */}
            {/* {user.origin && user.origin === "PITCHGROUND" && <Item key="activation_codes"><Link to='/activation-codes'>Activation Codes <Icon type="key" /></Link></Item>} */}
            <Item key="signouy"><Link to="/logout">Sign Out <Icon type="poweroff" /></Link></Item>
        </Menu>
    )

    return (
        <Header className="navbar">
            <div className="logo">
                <Link to={`/${domain.id}`}><img src={`${CDN_URL}/global/images/${props.auth.host.logos.small_logo}`} alt="logo-head" /></Link>
            </div>

            <Menu
                theme="dark"
                mode="horizontal"
            >
                <Item key={2}>
                    <Dropdown overlay={
                        <Menu className="navbar-user-dropdown">
                            <Item key="help_ticket"><a href={`mailto:${props.auth.host.support_email}`} target="_blank" rel="noreferrer noopener">Submit Ticket</a></Item>
                            <Item key="help_kb"><a href={props.auth.host.kb_url} target="_blank" rel="noreferrer noopener">Knowledge Base</a></Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" href="#/">
                            <Icon component={InfoCircle} />
                        </a>
                    </Dropdown>

                </Item>

                {/* <Item key={3} className="bell-icon">
                    <Icon component={Bell} />
                </Item> */}

                <Item key={1}>
                    <Dropdown overlay={userDropdownMenu} trigger={['click']}>
                        <a className="ant-dropdown-link" href="#/">
                            <Avatar style={{ backgroundColor: "#FC4747", verticalAlign: 'middle' }} size="large">
                                {user_fname[0]}
                            </Avatar>

                            <span className="avatar-details">
                                {user_fname}<br />
                                <span className="domain-name">{(domain && domain.dom_name) ? domain.dom_name : (user_fname.split(" ")[0] + "'s Domain")}</span>
                            </span>
                        </a>
                    </Dropdown>
                </Item>
            </Menu>
        </Header>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(Navbar);