import React, { Component } from 'react';
import './index.scss';
import { Card, Col, Row, Button, Icon } from 'antd';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrowright.svg';
import { ReactComponent as Img } from '../../../../assets/images/nextsteps.svg';
import StepsModal from './StepsModal';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class StepsCard extends Component {
    state = {
        modalVisible: false
    };

    toggleModal = () => {
        this.setState({
            ...this.state,
            modalVisible: !this.state.modalVisible
        })
    };

    render() {
        const { PIXEL, PROFILE, CAMPAIGN } = this.props;
        const domid = this.props.match.params.domid;
        return (
            <Card className="steps-card">
                <Row gutter={24}>
                    <Col xs={24} md={5} className="left">
                        <h2 className="number">{2 + Number(PIXEL) + Number(PROFILE) + Number(CAMPAIGN)}/5</h2>
                        <p className="text">steps completed!</p>

                        <Button type="default" onClick={this.toggleModal}>
                            View Steps <Icon component={Arrow} />
                        </Button>
                    </Col>

                    <Col xs={24} md={{ span: 12, offset: 1 }} className="right">
                        <h2 className="heading">Almost there!</h2>
                        <p className="disclaimer">
                            We recommend completing the remaining steps to get most out of {this.props.auth.host.franchise_name}. Let’s get done with it!
                        </p>

                        {
                            PIXEL !== "1" && PIXEL !== 1
                                ? <div>
                                    <Link to={`/${domid}/steps/3`} className="step-name"><span className="step-number">Step 3:</span> Add {this.props.auth.host.franchise_name} pixel</Link>
                                    <p className="step-detail">Add a universal JS code to your website to create trigger and manage campaigns from our dashboard directly.</p>
                                    <p className="bottom-text">Just add once and manage forever!</p>
                                </div>

                                : (
                                    PROFILE !== "1" && PROFILE !== 1
                                        ? <div>
                                            <Link to={`/${domid}/steps/4`} className="step-name"><span className="step-number">Step 4:</span> Complete your profile</Link>
                                            <p className="step-detail">Tell us a bit more about yourself for us to help you even better.</p>
                                        </div>
                                        : <div>
                                            <Link to={`/${domid}/new_campaign`} className="step-name"><span className="step-number">Step 5:</span> Launch a campaign</Link>
                                            <p className="step-detail">Setup a {this.props.auth.host.franchise_name} campaign and watch your conversions <b>grow</b>.</p>
                                        </div>
                                )
                        }
                    </Col>

                    <Col xs={0} md={{ span: 6, offset: 0 }} className="image">
                        <Img />
                    </Col>
                </Row>

                <StepsModal
                    domid={this.props.match.params.domid}
                    visible={this.state.modalVisible}
                    firstIncomplete={
                        PIXEL === 1 || PIXEL === "1"
                            ? (
                                PROFILE === 1 || PROFILE === "1"
                                    ? 'campaign' : 'profile'
                            )
                            : 'pixel'
                    }
                    closeModal={this.toggleModal}
                    {...this.props}
                />
            </Card>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(StepsCard);