import React from 'react';
import { Input, Icon } from 'antd';
const { Search } = Input;

const SearchField = (props) => {
  return (
    <Search
      placeholder={props.placeholder}
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      onSearch={value => props.onSearch(value)}
      style={props.style ? props.style : {}}
      suffix={
        props.value.trim() !== "" ?
          <Icon
            type="plus"
            style={{
              transform: "rotate(45deg)", position: "absolute", zIndex: "99", background: "white", fontSize: "16px", top: "0", left: "-1px",
            }}
            onClick={() => { props.onChange(""); props.onSearch("") }} /> :
          < span ></span >
      }
    />
  );
}

export default SearchField;