import React from 'react';
import './index.scss';
import { Table, Icon } from 'antd';
import { Link } from 'react-router-dom';
import CampaignPreview from '../CampaignPreview';

const CampaignOverview = (props) => {
    const { callouts } = props;
    const data = callouts.filter((c) => c.enabled === 1);
    const columns = [{
        title: 'Preview',
        dataIndex: 'preview',
        key: 'preview',
        width: '500px',
        render: (_, record) => <CampaignPreview camp={props.camp} calloutType={record.key} />
    }, {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    }];

    return (
        <div className="campaign-overview">
            <Table dataSource={data} columns={columns} pagination={false} locale={{ emptyText: <Link to={`/${props.camp.camp_domid["$id"]}/edit_campaign/${props.camp._id["$id"]}/2`}><Icon type="plus" /> Add More Alerts</Link> }} />
            {data.length > 0 &&
                <div style={{ textAlign: 'center' }}>
                    <Link to={`/${props.camp.camp_domid["$id"]}/edit_campaign/${props.camp._id["$id"]}/2`}><Icon type="plus" /> Add More Alerts</Link>
                </div>
            }
        </div>
    );
};

export default CampaignOverview;