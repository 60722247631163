import React from 'react';
import { Tabs, Skeleton } from 'antd';
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import NoData from '../../../Dashboard/Partials/NoData'
import { ReactComponent as NoGraph } from '../../../../assets/images/icons/no-graph.svg';
import { modifyDaywiseData } from '../../../../helpers/analytics';
import './index.scss';

const DayWiseStats = (props) => {
    let { data, type } = props;
    const cols = { date: { alias: "date" }, value: { alias: type === "visitor" ? "Visitors:" : "Clicks:" } };

    const tooltip = <Tooltip
        crosshairs={null}
        containerTpl="<div class=&quot;g2-tooltip&quot;><p class=&quot;g2-tooltip-title&quot;></p><table class=&quot;g2-tooltip-list&quot;></table></div>"
        itemTpl="<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span>{name}</td><td>{value}</td></tr>"
        offset={50}
        g2-tooltip={{
            position: "absolute",
            visibility: "hidden",
            border: "1px solid #000",
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            color: "#fff",
            opacity: "1",
            padding: "1rem 1.25rem",
            transition: "top 200ms,left 200ms",
            fontSize: "14px"
        }}
        g2-tooltip-list={{
            marginTop: "0.5rem"
        }}
    />;
    const placeholder = <NoData
        heading="Not enough data!"
        content="We could not find enough data to build a graph!"
        image={NoGraph}
    />

    data = modifyDaywiseData(data);
    const VisitorComponent = props.loading ? (<Skeleton active />) : (
        <Chart height={300} data={data} scale={cols} forceFit placeholder={placeholder}>
            <Axis
                name="date"
                visible={data.length < 15 ? true : false}
                tickLine={{
                    lineWidth: 2,
                    stroke: '#E9E9E9',
                    length: 7,
                }}
                line={{
                    stroke: "#E9E9E9",
                }}
                label={{
                    formatter: (text) => {
                        return (text.split(',')[0]);
                    }
                }}
            />
            <Axis
                name="value"
                grid={{
                    align: 'bottom',
                    type: 'line',
                    lineStyle: {
                        stroke: '#E9E9E9',
                        lineWidth: 1,
                        lineDash: [4, 4]
                    },
                }}
            />
            {tooltip}
            <Geom
                type="interval"
                position="date*value"
                color="l (270) 0:rgba(255, 47, 47, 1) .5:rgba(255, 47, 47, 1) 1:rgba(255, 47, 47, 1)"

                style={{
                    shadowColor: "l (270) 0:rgba(21, 146, 255, 0)",
                    shadowBlur: 60,
                    shadowOffsetY: 6,
                }}
                active={true}
            />
        </Chart>
    );

    return (
        <Tabs defaultActiveKey="1" className="daywise-stats-tabs" tabBarExtraContent={props.addons}>
            <Tabs.TabPane tab={`Daywise ${type === 'visitor' ? "Visitors" : "Clicks"}`} key="1">
                {VisitorComponent}
            </Tabs.TabPane>
        </Tabs>
    );
};

export default DayWiseStats;