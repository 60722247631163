import React, { Component } from 'react';
import FormContainer from '../FormContainer';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button, Tabs, Alert } from 'antd';
import axios from 'axios';

const TabPane = Tabs.TabPane;

class ForgotForm extends Component {

    state = {
        sentSuccessfully: false,
        loading: false,
        showMsg: false,
        msgType: 'error',
        msg: ''
    }

    componentDidMount() {
        document.title = this.props.title;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true }, () => {
                    axios({
                        url: '/login/forgot_password',
                        method: 'post',
                        data: {
                            email: values.email,
                        }
                    }).then((res) => {
                        if (res.data.success === 1) {
                            this.setState({
                                showMsg: true,
                                loading: false,
                                msgType: 'success',
                                msg: 'Please check your email inbox for password reset instructions',
                                sentSuccessfully: true
                            })
                        } else {
                            this.setState({
                                showMsg: true,
                                loading: false,
                                msgType: 'error',
                                msg: res.data.msg
                            })
                        }
                    }).catch((e) => {
                        this.setState({
                            showMsg: true,
                            loading: false,
                            msgType: 'error',
                            msg: e.message
                        })
                    })
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <FormContainer>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Reset Your Password" key="1">
                        <Form onSubmit={this.handleSubmit} className="forgot-form" noValidate>
                            <Form.Item>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, type: "email", message: 'Please enter a valid Email Id!' }],
                                })(
                                    <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Your Email Id" />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {this.state.showMsg && <Alert message={this.state.msg} type={this.state.msgType} />}
                                <Button size="large" type="primary" htmlType="submit" className="login-form-button" style={{ pointerEvents: this.state.sentSuccessfully ? 'none' : 'auto' }} loading={this.state.loading}>{this.state.msgType === 'success' ? 'Sent Successfully' : 'Continue'}</Button>
                                <Link to="/login" className="login-form-signup-here">Back to Login</Link>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>
            </FormContainer>
        );
    }
}

export default Form.create()(ForgotForm);
