import Axios from "axios";

export const getDomDetails = (domid) => {
    return Axios({
        url: '/settings/get_dom_details',
        method: 'post',
        data: {
            domid
        }
    }).then((res) => {
        const { dom_websites, dom_name, dom_settings, all_timezones } = res.data.dom_details;
        const dom_timezone = typeof (dom_settings) === "object" && Object.keys(dom_settings).length ? dom_settings.dom_timezone : '';
        const branding_on = typeof (dom_settings) === "object" && Object.keys(dom_settings).length ? dom_settings.branding.on : 0;
        const branding_name = typeof (dom_settings) === "object" && Object.keys(dom_settings).length ? dom_settings.branding.name : "";
        const branding_url = typeof (dom_settings) === "object" && Object.keys(dom_settings).length ? dom_settings.branding.url : "";
        return {
            dom_websites,
            dom_name,
            dom_timezone: dom_timezone ? dom_timezone.split('|')[0] : '',
            all_timezones,
            branding_on,
            branding_name,
            branding_url,
            dom_settings,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const saveDomDetails = (domid, dom_name, dom_settings, dom_websites) => {
    return Axios({
        url: '/settings/save_dom_details',
        method: 'post',
        data: {
            domid,
            dom_name,
            dom_settings,
            dom_websites
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false,
                loading: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.msg,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const getUsersByDomid = (domid) => {
    return Axios({
        url: '/settings/get_users_by_domId',
        method: 'post',
        data: {
            domid
        }
    }).then((res) => {
        return {
            users: res.data.users,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const addUser = (domid, name, role, email) => {
    return Axios({
        url: '/settings/add_user',
        method: 'post',
        data: {
            domid,
            name,
            role,
            email
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false,
                loading: false
            }
        } else {
            return {
                errorMsg: res.data.msg,
                error: true,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const editUser = (domid, uid, role) => {
    return Axios({
        url: '/settings/edit_user',
        method: 'post',
        data: {
            domid,
            uid,
            role
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false,
                loading: false
            }
        } else {
            return {
                error: true,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const deleteUser = (uid, domid) => {
    return Axios({
        url: '/settings/delete_user',
        method: 'post',
        data: {
            uid,
            domid
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false,
                loading: false
            }
        } else {
            return {
                error: true,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const getTranslations = (domid) => {
    return Axios({
        url: '/translations/get_translations',
        method: 'post',
        data: {
            domid
        }
    }).then((res) => {
        return {
            translations: res.data.translations,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const addTranslation = (domid, translation, edit) => {
    return Axios({
        url: '/settings/add_translation',
        method: 'post',
        data: {
            domid,
            translation,
            edit
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false,
                loading: false
            }
        } else {
            return {
                error: true,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const sendDeveloperEmail = (domid, email) => {
    return Axios({
        url: '/settings/send_developer_email',
        method: 'post',
        data: {
            domid,
            email
        }
    }).then((res) => {
        if (res.data.success) {
            return {
                error: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.msg
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message
        }
    });
};