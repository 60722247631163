import React, { Component } from 'react';
import { ReactComponent as CalloutPlaceholder } from '../../../../../assets/images/callouts/1.svg';
import { connect } from 'react-redux'
import { CDN_URL } from '../../../../../config';

class CampaignPreview extends Component {
  state = {
    currentPreviewIndex: 0,
    popupdata: { type: "user_activities" },
    error: false,
    rawHTML: ''
  }

  generatePopupData = (campaign, type) => {
    const defaultData = {
      camp_realtimevisitor: {
        "type": "visitor_count",
        "title": "<h>[alert_count] Marketers</h>",
        "message": " are viewing right now",
        "image": `${CDN_URL}/images/campaigns/81c60bbef15a02235548a270bf543d2d.gif`,
      },
      camp_recentactivity: {
        "type": "user_activities",
        "title": "[first_name]([place])",
        "message": "Recently purchased LetConvert.",
        "image": `${CDN_URL}/images/campaigns/7b9605333f3587faaa1e006c11500806.gif`,
      },
      camp_userreviews: {
        "type": "user_reviews",
        "image": `${CDN_URL}/images/campaigns/7b9605333f3587faaa1e006c11500806.gif"`,
        "name": "John Doe",
        "text": "Amazing Product!",
      },
      camp_powertrails: {
        "type": "power_trails",
        "title": "<h>[alert_count]</h> People",
        "message": "purchased in last [alert_window]",
        "image": `${CDN_URL}/images/campaigns/3d0cfa05ebad2cb706154c1399ccfa39.gif`,
      },
    }

    let popupdata = { ...defaultData[type] };

    if (type === "camp_powertrails") {
      popupdata["title"] = campaign.camp_powertrails.text ? campaign.camp_powertrails.text : campaign.camp_powertrails.title;
      popupdata["message"] = campaign.camp_powertrails.message ? campaign.camp_powertrails.message : campaign.camp_powertrails.text
      if (campaign.camp_powertrails.staticimage &&
        campaign.camp_powertrails.staticimage.enabled &&
        parseInt(campaign.camp_powertrails.staticimage.enabled) === 1 &&
        campaign.camp_powertrails.staticimage.name &&
        campaign.camp_powertrails.staticimage.name !== "") {
        popupdata.image = CDN_URL + "/images/campaigns/" + campaign.camp_powertrails.staticimage.name;
      }
    } else if (type === "camp_realtimevisitor") {
      popupdata["title"] = campaign.camp_realtimevisitor.text ? campaign.camp_realtimevisitor.text : campaign.camp_realtimevisitor.title;
      popupdata["message"] = campaign.camp_realtimevisitor.message ? campaign.camp_realtimevisitor.message : campaign.camp_realtimevisitor.text;
      if (campaign.camp_realtimevisitor.staticimage &&
        campaign.camp_realtimevisitor.staticimage.enabled &&
        parseInt(campaign.camp_realtimevisitor.staticimage.enabled) === 1 &&
        campaign.camp_realtimevisitor.staticimage.name &&
        campaign.camp_realtimevisitor.staticimage.name !== "") {
        popupdata.image = CDN_URL + "/images/campaigns/" + campaign.camp_realtimevisitor.staticimage.name;
      }
    } else if (type === "camp_recentactivity") {
      popupdata["title"] = campaign[type].title;
      popupdata["message"] = campaign[type].message ? campaign[type].message : campaign[type].title;
      if (campaign.camp_recentactivity.settings && campaign.camp_recentactivity.settings.staticimage &&
        campaign.camp_recentactivity.settings.staticimage.enabled &&
        parseInt(campaign.camp_recentactivity.settings.staticimage.enabled) === 1 &&
        campaign.camp_recentactivity.settings.staticimage.image &&
        campaign.camp_recentactivity.settings.staticimage.image !== "") {
        popupdata.image = CDN_URL + "/images/campaigns/" + campaign.camp_recentactivity.settings.staticimage.image;
      }
    } else if (type === "camp_userreviews") {
      if (campaign.camp_userreviews.previewdata) {
        if (campaign.camp_userreviews.previewdata.image) {
          popupdata.image = campaign.camp_userreviews.previewdata.image;
        }
        if (campaign.camp_userreviews.previewdata.name) {
          popupdata.name = campaign.camp_userreviews.previewdata.name;
        }
        if (campaign.camp_userreviews.previewdata.stars) {
          popupdata.stars = campaign.camp_userreviews.previewdata.stars;
        }
        if (campaign.camp_userreviews.previewdata.text) {
          popupdata.text = campaign.camp_userreviews.previewdata.text;
        }
      }
    }
    popupdata.campid = campaign._id['$id'];
    return popupdata;
  }

  generatePreviewString = (let_convert, campaign, popupdata) => {
    try {
      let previewHTML = "<style>#LTCNVRTELEMENT{ position: absolute !important; bottom: 10px !important; z-index: 99 !important;}</style>" + let_convert.getIframeHtml(let_convert.campaigns[campaign._id['$id']], popupdata).html.replace("display: none", "display: flex");

      if (this.props.calloutType === "camp_recentactivity") {
        previewHTML = previewHTML.replace(/\[first_name\]/g, "Bruce")
          .replace(/\[last_name\]/g, "Wayne")
          .replace(/\[full_name\]/g, "Bruce Wayne")
          .replace(/\[place\]/g, "Gotham, USA")
      }

      previewHTML = previewHTML.replace(/\[alert_count\]/g, "70");

      if (campaign.camp_powertrails && campaign.camp_powertrails.window && campaign.camp_powertrails.window.data && campaign.camp_powertrails.window.unit) {
        previewHTML = previewHTML.replace(/\[alert_window\]/g, campaign.camp_powertrails.window.data + " " + campaign.camp_powertrails.window.unit);
      }
      var randomMatches = previewHTML.match(/\[rand\(\d+,\s?\d+\)\]/g);
      if (randomMatches && randomMatches.length) {
        randomMatches.forEach(function (match) {
          var a = 1, b = 100;
          var groups = match.match(/\[rand\((\d+),\s?(\d+)\)\]/);
          try {
            if (groups[1]) a = parseInt(groups[1])
            if (groups[2]) b = parseInt(groups[2])
          } catch (e) { }
          var randomNum = Math.floor(Math.random() * b) + a;
          previewHTML = previewHTML.replace(match, randomNum);
        })
      }
      return previewHTML;
    } catch (e) {
      console.log(e);
      this.setState({ error: true })
    }
  }

  onLoad(props) {
    let let_convert = window.let_convert;
    let_convert.popup_conditions = {};
    let_convert.poweredbyname = props.auth.host.franchise_name;
    const campaign = props.camp;
    let_convert.campaigns[campaign._id['$id']] = campaign;
    window.letconvert_realtime[campaign._id['$id']] = 42;
    const popupdata = this.generatePopupData(campaign, props.calloutType);
    this.setState({
      popupdata
    }, () => this.loadPreviewContent())
  }

  componentDidMount() {
    this.onLoad(this.props)
  }

  componentWillReceiveProps(props) {
    this.onLoad(props);
  }

  loadPreviewContent = () => {
    try {
      this.setState({
        rawHTML: this.generatePreviewString(window.let_convert, this.props.camp, this.state.popupdata)
      })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  render() {
    if (this.state.error) {
      return <CalloutPlaceholder />
    }
    let height = 140;
    if (["template4", "template5"].indexOf(this.props.camp[this.props.calloutType].template) !== -1) {
      height = 260;
    }
    return (
      <div className="campaign-preview" style={{ width: 450, minHeight: height, position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.rawHTML }}>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}
export default connect(mapStateToProps)(CampaignPreview);