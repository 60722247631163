import React, { Component } from 'react';
import './index.scss';
import { Card, Table, Button, Icon, Row, Col, message, Modal, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import MainHeadingBar from '../../Partials/MainHeadingBar';
import { ReactComponent as ReviewsIcon } from '../../../../assets/images/icons/reviews.svg'
import { ReactComponent as DeleteReviewIcon } from '../../../../assets/images/icons/delete.svg'
import { getReviewPools, deleteReviewPools } from '../../../../helpers/ajax/reviewsAjax';
import NoData from '../../Partials/NoData';
import SearchField from '../../Partials/SearchField';

class ReviewPools extends Component {
    state = {
        selectedRowKeys: [],
        pools: [],
        loading: true,
        error: false,
        errorMsg: '',
        filters: {
            search: '',
        },
        paging: {
            pageSize: 10,
            total: 0,
            page: 1
        }
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    fetchReviewPools() {
        getReviewPools(
            this.props.match.params.domid,
            this.state.paging.pageSize,
            (this.state.paging.page - 1) * this.state.paging.pageSize,
            "_id",
            "-1",
            this.state.filters.search
        ).then(res => this.setState(res));
    }

    componentDidMount() {
        this.fetchReviewPools();
    }

    setFilter(key, value) {
        this.setState({
            filters: {
                ...this.state.filters,
                [key]: value
            },
            loading: true,
            paging: {
                pageSize: 10,
                total: 0,
                page: 1
            }
        }, () => this.fetchReviewPools())
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            reviews: [],
            loading: true,
            paging: {
                ...this.state.pagination,
                page: pager.current
            }
        }, () => this.fetchReviewPools());
    };

    deleteSelectedReviewPools(ids) {
        deleteReviewPools(this.props.match.params.domid, ids)
            .then((res) => {
                if (res.data.success === 1) {
                    this.setState({
                        pools: this.state.pools.filter((r) => ids.indexOf(r._id) === -1)
                    })
                    message.success("Pool(s) deleted successfully!");
                } else {
                    message.error(res.data.msg);
                }
            }).catch(e => {
                message.error(e.message);
            })
    }

    confirmDelete(ids) {
        const that = this;
        Modal.confirm({
            title: 'Are you sure',
            content: 'Are you sure you want to delete the selected review pools(s). Once deleted, review pools cannot be recovered',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                that.deleteSelectedReviewPools(ids);
            }
        });
    }

    render() {
        const { selectedRowKeys, filters } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [{
            title: 'Pool Name',
            dataIndex: "_id",
        }, {
            title: 'Number of Reviews',
            dataIndex: 'count',
        }, {
            title: 'Type',
            dataIndex: 'pool_type',
        }, {
            title: 'Actions',
            dataIndex: 'id',
            width: '350px',
            render: (_, record) => <div className="actions-div">
                <Tooltip title="Show Reviews"><Link to={`/${this.props.match.params.domid}/reviews/${record._id}`} className="ant-btn edit-btn">
                    <Icon component={ReviewsIcon} /></Link></Tooltip>
                <Tooltip title="Delete Pool"><Button type="danger" className="delete-btn" onClick={() => this.confirmDelete([record._id])}><Icon component={DeleteReviewIcon} /></Button></Tooltip>
            </div>
        }];

        return (
            <div className="review-pools">
                <MainHeadingBar title="Review Pools" helpText="Here you can manage the review pools." {...this.props} />
                <Card style={{ padding: "30px" }}>
                    <Row style={{ marginBottom: "2.85rem" }}>
                        <Col xs={12}>
                            {this.state.selectedRowKeys.length > 0 &&
                                <Button
                                    style={{ background: "#F84B3D", color: "white" }}
                                    onClick={() => this.confirmDelete(this.state.selectedRowKeys)}>Delete Selected</Button>}
                        </Col>
                        <Col xs={12} style={{ textAlign: "right" }}>
                            <span>Search</span>
                            <SearchField
                                placeholder="Enter Keywords"
                                value={this.state.filters.search}
                                onChange={val => this.setState({ filters: { ...this.state.filters, search: val } })}
                                onSearch={value => this.setFilter('search', value)}
                                style={{ width: "20rem", marginLeft: "15px" }}
                            />
                        </Col>
                    </Row>
                    <Table
                        rowKey={record => record._id}
                        dataSource={this.state.pools}
                        columns={columns}
                        pagination={{ total: this.state.total }}
                        rowSelection={rowSelection}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        locale={
                            filters.search.length
                                ? {
                                    emptyText: <NoData
                                        heading="No Review Pools Found!"
                                        content=""
                                    />
                                }
                                : {
                                    emptyText: <NoData
                                        heading="No Review Pools Found!"
                                    />
                                }
                        }
                    />
                </Card>
            </div>
        );
    }
}

export default ReviewPools;