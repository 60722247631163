import React, { Component } from 'react';
import './index.scss';
import { Button, Input, message, Icon, Alert } from 'antd';
import { verifyPixel } from '../../../../helpers/verifyPixel';
import { ReactComponent as Reload } from '../../../../assets/images/icons/reload.svg';
import { connect } from 'react-redux';
import axios from 'axios';

class PixelVerificationAction extends Component {
    state = {
        error: false,
        errorMsg: '',
        loading: false,
        websiteUrl: '',
        validUrl: false,
        verified: false,
        alertVisible: false,
        tried: false,
    };

    changeState = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        })
    };

    checkPixelRequest = (count) => {
        if (count > 0) {
            axios({
                url: '/settings/check_dom_installed',
                method: 'post',
                data: {
                    domid: this.props.id,
                    "hashid": window.verifypixelhashid
                }
            }).then((res) => {
                if (res.data.success === 1) {
                    message.destroy();
                    message.success('URL verified!');
                    this.setState({
                        websiteUrl: '',
                        verified: true,
                        loading: false
                    })
                } else {
                    setTimeout(() => {
                        this.checkPixelRequest(--count);
                    }, 3000)
                }
            }).catch((e) => {
                setTimeout(() => {
                    this.checkPixelRequest(count);
                }, 3000)
            });
        } else {
            message.destroy();
            this.setState({
                verified: false,
                loading: false,
                alertVisible: true
            })
        }
    }

    validateUrl = () => {
        this.setState({
            tried: true
        }, () => {
            let { websiteUrl } = this.state;

            if (websiteUrl.match(/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
                this.setState({
                    ...this.state,
                    validUrl: true
                }, () => {
                    websiteUrl = (
                        websiteUrl.slice(0, 7) === 'http://' ||
                        websiteUrl.slice(0, 8) === 'https://'
                    ) ? websiteUrl : `http://${websiteUrl}`;

                    verifyPixel(websiteUrl);
                    message.loading('Verifying Pixel', 0);
                    this.checkPixelRequest(5);
                });
            } else {
                this.setState({
                    ...this.state,
                    validUrl: false
                });
            }
        });
    };

    componentWillUnmount() {
        message.destroy();
    }

    render() {
        const { websiteUrl, validUrl, verified, alertVisible, tried } = this.state;

        return (
            <div className="pixel-verification-action">
                <p className="disclaimer">
                    Let’s verify that the {this.props.auth.host.franchise_name} pixel was installed successfully on your website. Enter the URL where you’ve installed the {this.props.auth.host.franchise_name} pixel and click on verify.
                </p>
                <p className="disclaimer disclaimer-bottom">(Your website will be opened in a new window to verify your installation)</p>

                <div className={`${tried && !validUrl && 'has-error invalid'} ${validUrl && !verified && "has-error"}`}>
                    <Input
                        placeholder="Enter your website URL"
                        className="dev-email"
                        value={websiteUrl}
                        onChange={e => this.changeState("websiteUrl", e.target.value)}
                    />
                    <span className="error-label">Invalid Url!</span>
                </div>

                {
                    tried
                        ? !verified
                            ? <Button type="default" className="pixel-btn" onClick={this.validateUrl}>
                                Try Again <Icon component={Reload} />
                            </Button>
                            : <Button type="default" className="pixel-btn" onClick={this.validateUrl}>
                                Verify Installation
                            </Button>
                        : <Button type="default" className="pixel-btn" onClick={this.validateUrl}>
                            Verify Installation
                    </Button>
                }

                {
                    tried && validUrl && !verified && {}.toString.call(this.props.onSkip) === '[object Function]'
                    && <span className="skip" onClick={this.props.onSkip}>Skip for now</span>
                }

                {
                    !verified && alertVisible
                        ? <Alert
                            message="Verification error!"
                            description={`Looks like our tracking wizards are having trouble finding ${this.props.auth.host.franchise_name} on this URL.`}
                            type="error"
                            showIcon
                            closable={true}
                            afterClose={() => this.changeState('alertVisible', false)}
                        />
                        : ''
                }
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(PixelVerificationAction);