import React from 'react';
import { Alert } from 'antd';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.scss';

const Exhausted = (props) => {
  const { domain, plan } = props.auth;
  let description = "The monthly unique visitor limit allotted for this account has been exhausted. Please contact your account owner to increase the views.";
  if (domain.role === "owner") {
    if (plan.plan_features.DOM_COUNT === 1) {
      description = <span>The monthly unique visitor limit allotted for this account has been exhausted. Please <a href="https://help.letconvert.com" target="_blank" rel="noreferrer noopener">contact support</a> to increase the views or upgrade your plan.</span>
    } else {
      description = <span>The monthly unique visitor limit allotted for this account has been exhausted. Please go to your <Link to="/manage-account">Manage account</Link> page and increase the views or upgrade your plan.</span>;
    }
  }
  return (
    <div className="account-deactivated">
      <MainHeadingBar title="Account Deactivated" {...props} />
      <Alert
        message="Warning"
        description={description}
        type="warning"
        showIcon
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  };
};
export default connect(mapStateToProps)(Exhausted);