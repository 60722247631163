import React, { Component } from 'react';
import './index.scss';
import { Card, Tabs, Menu, Dropdown, Tag, Icon, Button, Switch, Alert, Modal, Skeleton, Row } from 'antd';
import CampaignOverview from './CampaignOverview';
import CampaignDetails from './CampaignDetails';
import moment from 'moment';
import RecordsTable from '../RecordsTable';
import { BASE_URL } from '../../../../config';
import { ReactComponent as Click } from '../../../../assets/images/icons/click.svg';
import { ReactComponent as User } from '../../../../assets/images/icons/user.svg';
import { ReactComponent as Linechart } from '../../../../assets/images/icons/linechart.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRecords, toggleRecordStatus, deleteRecords } from '../../../../helpers/ajax/recordsAjax';

const { TabPane } = Tabs;
const { Item, Divider } = Menu;

class CampaignCard extends Component {
    state = {
        cardState: this.props.cardState || 'open',
        activeTabKey: '1',
        loading: true,
        error: false,
        errorMsg: '',
        selectedRowKeys: [],
        limit: 10,
        offset: 0,
        records: [],
        pagination: { showSizeChanger: true, total: 200 },
        sortField: '',
        sortOrder: '',
        calloutToggleAlertVisible: false,
        loadingRecords: true
    };

    changeState = (newState) => {
        this.setState({
            ...this.state,
            ...newState
        });
    };

    componentDidMount = () => {
        this.fetch();
    };

    fetch = () => {
        const { domain } = this.props.auth;
        const { limit, offset, sortField, sortOrder } = this.state;

        this.setState({
            ...this.state,
            loadingRecords: true
        }, () => {
            getRecords(domain.id, limit, offset, sortField, sortOrder, '', this.props.campaign._id.$id).then(res => {
                const { pagination } = this.state;
                pagination.total = res.total;

                this.setState({
                    ...this.state,
                    ...res,
                    pagination,
                    loadingRecords: false
                });
            })
        });
    };

    onSelectChange = (selectedRowKeys, actionRealtedValue) => {
        this.setState({
            selectedRowKeys
        }, () => {
            const { domain } = this.props.auth;

            if (typeof (actionRealtedValue) === "number") {
                Modal.confirm({
                    title: 'Are you sure?',
                    content: 'This will change the status of the selected record(s)',
                    okText: 'Confirm',
                    onOk: () => toggleRecordStatus(domain.id, this.state.selectedRowKeys, !actionRealtedValue).then(res => {
                        this.fetch();
                    })
                });
            } else if (typeof (actionRealtedValue === "string") && actionRealtedValue === "delete") {
                Modal.confirm({
                    title: 'Are you sure?',
                    content: 'This will delete the selected record(s)',
                    okText: 'Confirm',
                    onOk: () => deleteRecords(domain.id, this.state.selectedRowKeys).then(res => {
                        this.setState({
                            selectedRowKeys: []
                        }, () => this.fetch());
                    })
                });
            }
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            ...this.state,
            limit: pagination.pageSize,
            offset: pagination.pageSize * (pagination.current - 1),
            pagination: pager,
            sortField: Object.keys(sorter).length ? sorter.field : '',
            sortOrder: Object.keys(sorter).length ? sorter.order : '',
        }, () => this.fetch());
    };

    render() {
        const { cardState, activeTabKey, selectedRowKeys, records, pagination, loading, error, calloutToggleAlertVisible, loadingRecords } = this.state;
        const { toggleCalloutStatus, toggleCampaignStatus, toggleCampaignArchive } = this.props;

        if (loading) {
            return <Card style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </Card>
        }

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={this.state.errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        const dataSource = records.map(record => ({
            key: record.id,
            id: record.id,
            name: record.data.name,
            email: record.data.email,
            country: record.data.country,
            timestamp: moment(record.time).format("MMM DD, YYYY HH:mm"),
            source: record.source,
            status: record.status
        }));

        const {
            camp_name,
            camp_captureurl,
            camp_captureurlenabled,
            camp_created,
            camp_displayurl,
            camp_powertrails,
            camp_realtimevisitor,
            camp_recentactivity,
            camp_source,
            camp_status,
            camp_userreviews,
            _id,
            camp_domid,
            camp_analytics,
            records_tracked,
            camp_archived,
            draft
        } = this.props.campaign;
        camp_powertrails.type = 'Power Trail'; camp_powertrails.key = 'camp_powertrails';
        camp_realtimevisitor.type = 'Real Time Visitor'; camp_realtimevisitor.key = 'camp_realtimevisitor';
        camp_recentactivity.type = 'Recent Activity Alert'; camp_recentactivity.key = 'camp_recentactivity';
        camp_userreviews.type = 'User Review'; camp_userreviews.key = 'camp_userreviews';

        let unique_visitors = 0, overall_clicks = 0;
        if (typeof (camp_analytics) === "object" && Object.keys(camp_analytics).length) {
            const { unique, clicks } = camp_analytics;
            if (typeof (unique) === "object" && Object.keys(unique).length) {
                unique_visitors = unique.views;
            }
            if (typeof (clicks) === "object" && Object.keys(clicks).length) {
                if (typeof (clicks.user_activities) === "object" && Object.keys(clicks.user_activities).length) {
                    if (typeof (clicks.user_activities.overall) === "object" && Object.keys(clicks.user_activities.overall).length) {
                        overall_clicks = clicks.user_activities.overall.clicked;
                    }
                }
            }
        }

        const menu = (
            draft === 1 || draft === '1'
                ? (
                    <Menu className="actions-menu campaign-card-actions-menu">
                        <Item key="0">
                            <a href={`${BASE_URL}${camp_domid.$id}/edit_campaign/${_id.$id}`}>Edit</a>
                        </Item>
                    </Menu>
                )
                : (
                    <Menu className="actions-menu campaign-card-actions-menu">
                        <Item key="0">
                            <Link to={`/${camp_domid.$id}/edit_campaign/${_id.$id}`}>Edit</Link>
                        </Item>
                        <Divider />
                        <Item key="1">
                            <Link to={`/${camp_domid.$id}/campaign-report/${_id.$id}`}>Report</Link>
                        </Item>
                        <Divider />
                        <Item key="2">
                            <Link to={`/${camp_domid.$id}/new_campaign/${_id.$id}`}>Clone</Link>
                        </Item>
                        <Divider />
                        <Item key="3">
                            {
                                camp_archived === 1
                                    ? <a href='#/' onClick={() => toggleCampaignArchive(_id.$id, 1)}>Unarchive</a>
                                    : <a href='#/' onClick={() => toggleCampaignArchive(_id.$id, 0)}>Archive</a>
                            }
                        </Item>
                        {
                            (camp_archived !== 1 && camp_archived !== '1') &&
                            <Divider />
                        }
                        {
                            (camp_archived !== 1 && camp_archived !== '1') &&
                            <Item key="4">
                                {
                                    camp_status === 1 || camp_status === '1'
                                        ? <a href={`#/`}>
                                            Active <Switch
                                                defaultChecked
                                                checked={camp_status === 1}
                                                size="small"
                                                onChange={() => toggleCampaignStatus(_id.$id, 1)}
                                            />
                                        </a>
                                        : <a href={`#/`}>
                                            Inactive
                                        <Switch
                                                size="small"
                                                checked={camp_status === 1}
                                                onChange={() => toggleCampaignStatus(_id.$id, 0)}
                                            /></a>
                                }
                            </Item>
                        }
                    </Menu>
                )
        );

        const extraContent = (
            selectedRowKeys.length &&
            activeTabKey === '3' &&
            <div className={`buttons-div ${selectedRowKeys.length || 'hidden'}`}>
                <Button type="danger" onClick={() => this.onSelectChange(selectedRowKeys, "delete")}>Delete</Button>
                <Button type="danger" onClick={() => this.onSelectChange(selectedRowKeys, 1)}>Disable Selected</Button>
                <Button type="primary" onClick={() => this.onSelectChange(selectedRowKeys, 0)}>Enable Selected</Button>
            </div>
        );

        return (
            <div className="campaign-card-container">
                <Card className="campaign-card" title={
                    <div className="card-title-div">
                        <div className="name-div">
                            <p className="name">{camp_name || 'No Name Set'}</p>
                            {
                                (draft === 1 || draft === '1') ? (
                                    <Tag color="#36C626" className="active-tag tag">Draft</Tag>
                                ) : (
                                        (
                                            typeof (camp_status) === 'number' &&
                                            camp_status === 1
                                        ) ? <Tag color="#36C626" className="active-tag tag">Active</Tag>
                                            : <Tag color="#F84B3D" className="active-tag tag">Inactive</Tag>
                                    )
                            }
                        </div>

                        <div className="created-at-div">
                            <p className="created-at">Created On: {moment(camp_created).format("MMM DD, YYYY")}</p>
                        </div>

                        <div className="stats-div">
                            <span className="stats"><Icon component={User} /> <br /> {unique_visitors} unique <br /> visitor(s)</span>
                            <span className="stats"><Icon component={Linechart} /> <br />
                                <span className="normal-stat">{records_tracked} record(s) <br /> tracked</span>
                                <span className="hover-stat">{unique_visitors === 0 ? 0 : (records_tracked / unique_visitors * 100).toFixed(2)}%  record(s) <br /> tracked</span>
                            </span>
                            <span className="stats"><Icon component={Click} /> <br />
                                <span className="normal-stat">{overall_clicks} click(s) <br /> on callouts</span>
                                <span className="hover-stat">{unique_visitors === 0 ? 0 : (overall_clicks / unique_visitors * 100).toFixed(2)}%  click(s) <br /> on callouts</span>
                            </span>
                        </div>

                        <div className="actions-div">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a className="ant-dropdown-link" href="#/">
                                    More Actions  <Icon type="down" />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                }>
                    {
                        cardState === "open"
                            ? (
                                <div>
                                    <Tabs
                                        defaultActiveKey="1"
                                        tabBarExtraContent={extraContent}
                                        onChange={(activeKey) => { this.changeState({ 'activeTabKey': activeKey }) }}
                                    >
                                        <TabPane tab="Overview" key="1">
                                            <CampaignOverview
                                                camp={this.props.campaign}
                                                callouts={[
                                                    camp_realtimevisitor,
                                                    camp_recentactivity,
                                                    camp_powertrails,
                                                    camp_userreviews
                                                ]}
                                                onToggleStatus={(key, status) => {
                                                    if (
                                                        camp_status === 0 ||
                                                        camp_status === '0' ||
                                                        camp_archived === 1 ||
                                                        camp_archived === '1' ||
                                                        draft === '1' ||
                                                        draft === 1
                                                    ) {
                                                        this.changeState({ calloutToggleAlertVisible: 1 })
                                                        return '';
                                                    }
                                                    return toggleCalloutStatus(key, status, _id.$id);
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Details" key="2">
                                            <CampaignDetails {...{
                                                name: camp_name,
                                                created_at: camp_created,
                                                display_url: camp_displayurl,
                                                record_source: camp_source,
                                                record_url: (camp_captureurlenabled ? camp_captureurl : camp_displayurl),
                                                webhook_url: `${BASE_URL}webhooks/${_id.$id}`,
                                                timezone: this.props.auth.domain.dom_timezone
                                            }} />
                                        </TabPane>
                                        {!(draft === 1 || draft === '1') && <TabPane tab="Records" key="3">
                                            <RecordsTable
                                                dataSource={dataSource}
                                                searchedText=''
                                                rowSelection={{
                                                    selectedRowKeys,
                                                    onChange: this.onSelectChange,
                                                }}
                                                onToggleStatus={(id, status) => this.onSelectChange([id], status)}
                                                onDelete={id => this.onSelectChange([id], "delete")}
                                                pagination={pagination}
                                                handleTableChange={this.handleTableChange}
                                                loading={loadingRecords}
                                            />
                                        </TabPane>}
                                    </Tabs>

                                    <p
                                        className="card-length-changer"
                                        onClick={() => this.changeState({ 'cardState': 'closed' })}
                                    >
                                        Less Details <Icon type="arrow-up" />
                                    </p>
                                </div>
                            )
                            : (
                                <p
                                    className="card-length-changer"
                                    onClick={() => this.changeState({ 'cardState': 'open', 'activeTabKey': '1' })}
                                >
                                    More Details <Icon type="arrow-down" />
                                </p>
                            )
                    }
                </Card>

                {
                    calloutToggleAlertVisible &&
                    (
                        (camp_archived === 1 || camp_archived === '1')
                            ? (
                                <Alert
                                    message="This campaign is archived!"
                                    description={`Unarchive your campaign to enable ${this.props.auth.host.franchise_name} Callouts for this campaign.`}
                                    type="warning"
                                    showIcon
                                    closable={true}
                                    afterClose={() => this.changeState({ calloutToggleAlertVisible: false })}
                                />
                            )
                            : (
                                (draft === '1' || draft === 1) ? (
                                    <Alert
                                        message="This campaign is incomplete!"
                                        description="The campaign was saved as a draft because it was not completed. Please complete it to %take action.%"
                                        type="warning"
                                        showIcon
                                        closable={true}
                                        afterClose={() => this.changeState({ calloutToggleAlertVisible: false })}
                                    />
                                ) : (
                                        (camp_status === '0' || camp_status === 0) &&
                                        <Alert
                                            message="This campaign is inactive!"
                                            description={`Activate your campaign to enable ${this.props.auth.host.franchise_name} Callouts for this campaign.`}
                                            type="warning"
                                            showIcon
                                            closable={true}
                                            afterClose={() => this.changeState({ calloutToggleAlertVisible: false })}
                                        />
                                    )
                            )
                    )
                }
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(CampaignCard);
