import React, { Component } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import Login from './Components/Auth/Login/';
import ForgotPassword from './Components/Auth/ForgotPassword';
import AddUserSignup from './Components/Auth/AddUserSignup';
import ResetPassword from './Components/Auth/ResetPassword';
import Logout from './Components/Auth/Logout';
import Dashboard from './Components/Dashboard';
import Snapshot from './Components/Dashboard/Snapshot';
import Deactivated from './Components/Dashboard/Deactivated';
import Exhausted from './Components/Dashboard/Exhausted';
import Records from './Components/Dashboard/Records';
import UserReviews from './Components/Dashboard/Reviews/UserReviews';
import ReviewPools from './Components/Dashboard/Reviews/ReviewPools';
import ReviewSources from './Components/Dashboard/Reviews/ReviewSources';
import Library from './Components/Dashboard/Library';
import SearchMedia from './Components/Dashboard/SearchMedia';
import ActiveCampaigns from './Components/Dashboard/Campaigns/ActiveCampaigns';
import ArchivedCampaigns from './Components/Dashboard/Campaigns/ArchivedCampaigns';
import DraftCampaigns from './Components/Dashboard/Campaigns/DraftCampaigns';
import NewCampaign from './Components/Dashboard/NewCampaign';
import ProfileForm from './Components/Dashboard/ProfileSettings';
import ActivationCodes from './Components/Dashboard/ActivationCodes';
import ManageAccounts from './Components/Dashboard/ManageAccounts';
import Settings from './Components/Dashboard/Settings';
import NotFound404 from './Components/NotFound404';
import Billing from './Components/Dashboard/Billing';
import Onboarding from './Components/Onboarding';
import SwitchToAccount from './Components/Dashboard/SwitchToAccount';
import Steps from './Components/Steps';
import CampaignReport from './Components/Dashboard/CampaignReport.js';
import { connect } from 'react-redux';
import { getHostData } from './store/actions/authActions';
import { CDN_URL } from './config';

class App extends Component {
	state = {
		loading: true
	}
	componentDidMount() {
		this.props.getHostDataRequest();
	}

	addScript(src) {
		const s = document.createElement('script');
		if (src.match(/^<script.*src=['"](.*)['"].*><\/script>$/)) {
			s.src = src.match(/^<script.*src=['"](.*)['"].*><\/script>$/)[1];
		} else {
			s.innerHTML = src.replace(/<\/?script.*?>/g, '');
		}
		const body = document.querySelector("body");
		body.appendChild(s);
	}

	componentDidUpdate() {
		if (this.props.auth.host.franchise_name && this.state.loading) {
			document.querySelector("link[rel*='icon']").href = `${CDN_URL}/global/images/${this.props.auth.host.logos.favicon}`;
			if (this.props.auth.host.submitticket_pixel) {
				this.addScript(this.props.auth.host.submitticket_pixel)
			}
			if (this.props.auth.host.livechat_pixel) {
				this.addScript(this.props.auth.host.livechat_pixel)
			}
			this.setState({
				loading: false
			})
		}
	}

	render() {
		if (this.state.loading) {
			return <p></p>
		}
		return (
			<BrowserRouter>
				<div className="content-body">
					<Switch>

						<Route
							exact
							path='/'
							render={(props) => (
								<Redirect to="/switch-account" />
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Snapshot`}>
									<Snapshot {...props} />
								</Dashboard>
							)}
						/>

						<Route exact path='/login' render={(props) => <Login {...{ ...props, title: `${this.props.auth.host.franchise_name} | Login` }} />} />
						<Route path='/login/forgot/:hash' render={(props) => <ResetPassword {...{ ...props, title: `${this.props.auth.host.franchise_name} | Reset Password` }} />} />
						<Route path='/login/adduser/:hash' render={(props) => <AddUserSignup {...{ ...props, title: `${this.props.auth.host.franchise_name} | Signup` }} />} />
						<Route path='/forgot-password' render={(props) => <ForgotPassword {...{ ...props, title: `${this.props.auth.host.franchise_name} | Forgot Password` }} />} />

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/active_campaigns'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Active Campaigns`} openKeys={["submenu_campaigns"]}>
									<ActiveCampaigns {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/archived_campaigns'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Archived Campaigns`} openKeys={["submenu_campaigns"]}>
									<ArchivedCampaigns {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/draft_campaigns'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Draft Campaigns`} openKeys={["submenu_campaigns"]}>
									<DraftCampaigns {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/new_campaign/:campid([a-fA-F0-9]{24})?'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | New Campaign`} openKeys={["submenu_campaigns"]}>
									<NewCampaign {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/edit_campaign/:campid([a-fA-F0-9]{24})/:page([0-9])?'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Edit Campaign`} openKeys={["submenu_campaigns"]}>
									<NewCampaign {...props} edit={true} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/records'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Records`}>
									<Records {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/reviews/:pool?'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | User Reviews`} openKeys={["submenu_reviews"]}>
									<UserReviews {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/review-pools'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Review Pools`} openKeys={["submenu_reviews"]}>
									<ReviewPools {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/review-sources'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Review Sources`} openKeys={["submenu_reviews"]}>
									<ReviewSources {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/search-gifs'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Search GIFs`} openKeys={["submenu_library"]}>
									<SearchMedia {...props} type="gifs" />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/search-icons'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Search Icons`} openKeys={["submenu_library"]}>
									<SearchMedia {...props} type="icons" />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | My Library`} openKeys={["submenu_library"]}>
									<Library {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Settings`}>
									<Settings {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/settings'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Profile Settings`}>
									<ProfileForm {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/activation-codes'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Activation Codes`}>
									<ActivationCodes {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/manage-accounts'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Manage Accounts`}>
									<ManageAccounts {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/billing'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Billing`}>
									<Billing {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/onboarding'
							render={(props) => (
								<Onboarding title={`${this.props.auth.host.franchise_name} | Onboarding`} {...props} />
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/steps/:stepid([0-9])?'
							render={(props) => (
								<Steps title={`${this.props.auth.host.franchise_name} | Onboarding`} {...props} />
							)}
						/>

						<Route path='/switch-account/:domid([a-fA-F0-9]{24})?' render={(props) => <SwitchToAccount {...props} />} />

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/campaign-report/:campid([a-fA-F0-9]{24})'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name} | Campaign Report`}>
									<CampaignReport {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/deactivated'
							render={(props) => (
								<Dashboard {...props} title="LetConvert">
									<Deactivated {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/exhausted'
							render={(props) => (
								<Dashboard {...props} title="LetConvert">
									<Exhausted {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/logout'
							render={(props) => (
								<Dashboard {...props} title={`${this.props.auth.host.franchise_name}`}>
									<Logout />
								</Dashboard>
							)}
						/>

						<Route
							path='*'
							render={() => (
								<NotFound404 />
							)}
						/>
					</Switch>
				</div>
			</BrowserRouter >
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getHostDataRequest: () => dispatch(getHostData())
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
