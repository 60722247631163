import React from 'react';
import { Tooltip } from 'antd';
import './index.scss';

class ClickToCopy extends React.Component {
    state = {
        copySuccess: this.props.defaultText ? this.props.defaultText : "Copy"
    }

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({
            copySuccess: 'Copied!'
        });
    };

    render() {
        return (
            <span>
                {
                    document.queryCommandSupported('copy') &&
                    <span>
                        <Tooltip
                            title={this.state.copySuccess}
                            onVisibleChange={(visible) => !visible && this.setState({ copySuccess: this.props.defaultText ? this.props.defaultText : "Copy" })}
                        >
                            <a href="#/" onClick={this.copyToClipboard}>
                                {this.props.children}
                            </a>
                        </Tooltip>

                        <textarea
                            style={{ width: 0, height: "0 !important", padding: 0, margin: 0, borderColor: "transparent", lineHeight: 0, outline: 'none', background: 'transparent' }}
                            ref={(textarea) => this.textArea = textarea}
                            value={this.props.textToCopy}
                            readOnly
                        />
                    </span>
                }
            </span>
        );
    }
}

export default ClickToCopy;