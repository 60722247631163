import React from 'react';
import './index.scss';
import { Row, Col, Icon } from 'antd';
import moment from 'moment';
import ClickToCopy from '../../ClickToCopy';

const CampaignDetails = (props) => {
    let {
        name,
        created_at,
        display_url,
        record_source,
        record_url,
        webhook_url,
        timezone
    } = props;

    display_url = typeof(display_url) === "object" && display_url instanceof Array ? display_url : [{type: '', data: ''}];
    record_url = typeof(record_url) === "object" && record_url instanceof Array ? record_url : [{type: '', data: ''}];
    
    return(
        <div className="campaign-details">
            <Row>
                <Col xs={24} md={11}>
                    <h3 className="title">Campaign Name</h3>
                    <p className="text name">{ name }</p>
                </Col>
                <Col xs={24} md={{ span: 11, offset: 1 }}>
                    <h3 className="title">Record Source</h3>
                    <p className="text record-source">{ record_source.split('_').join(' ').toLowerCase() }</p>
                </Col>
            </Row>

            <Row>
                <Col xs={24} md={11}>
                    <h3 className="title">Created On</h3>
                    <p className="text">{
                        `${moment(created_at).format('MMM DD, YYYY HH:mm:ss')} ${
                            typeof(timezone) === "string" ? timezone : ''
                        }`
                    }</p>
                </Col>
                <Col xs={24} md={{ span: 11, offset: 1 }}>
                    <h3 className="title">Record URL</h3>
                    <p className="text">{
                        record_url[0].type.trim().length
                        ? <span>
                            <span className="type">
                                { record_url[0].type.toLowerCase() }
                            </span>
                            <span className="data">
                                : { record_url[0].data } {
                                    record_url.length > 1 ? `+${record_url.length -1} more` : ``
                                }
                            </span>
                        </span>
                        : 'Value Not Set'
                    }</p>
                </Col>
            </Row>

            <Row>
                <Col xs={24} md={11}>
                    <h3 className="title">Display URL</h3>
                    <p className="text">
                        { 
                            display_url[0].type.trim().length
                            ? <span>
                                <span className="type">
                                    { display_url[0].type.toLowerCase() }
                                </span>
                                <span className="data">
                                    : { display_url[0].data } {
                                        display_url.length > 1 ? `+${display_url.length -1} more` : ``
                                    }
                                </span>
                            </span>
                            : 'Value Not Set'
                        }
                    </p>
                </Col>
                <Col xs={24} md={{ span: 11, offset: 1 }}>
                    <h3 className="title"><span>Webhook URL</span> {
                        webhook_url.trim().length
                        ? <ClickToCopy defaultText="Copy Webhook URL" textToCopy={webhook_url}><Icon type="copy" /></ClickToCopy>
                        : ''
                    }</h3>
                    <p className="text webhook-url">{
                        webhook_url.trim().length
                        ? webhook_url
                        : 'Value Not Set'
                    }</p>
                </Col>
            </Row>
        </div>
    );
};

export default CampaignDetails;