import React, { Component } from 'react';
import './index.scss';
import { sendDeveloperEmail } from '../../../../helpers/ajax/settingsAjax';
import { Button, Input, message } from 'antd';

class SendCodeToTeammate extends Component {
    state = {
        error: false,
        errorMsg: '',
        developerEmail: '',
        validEmail: false,
        sent: false,
        tried: false,
    };

    changeState = (newState) => {
        this.setState({
            ...this.state,
            ...newState
        })
    };

    validateEmail = () => {
        this.setState({
            ...this.state,
            tried: true,
            error: false
        }, () => {
            if (this.state.developerEmail.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
                this.setState({
                    ...this.state,
                    validEmail: true
                }, () => sendDeveloperEmail(this.props.id, this.state.developerEmail).then(res => {
                    if (!res.error) {
                        this.setState({
                            ...this.state,
                            developerEmail: ''
                        }, () => this.props.onSuccess());
                    } else {
                        message.error(res.errorMsg || 'There was an error');
                    }
                }));
            } else {
                this.changeState({error: true, 'errorMsg': 'Invalid Email!'});
            }
        });
    };

    render() {
        const { developerEmail, errorMsg, validEmail, tried } = this.state;

        return(
            <div className="send-code-to-teammate">
                <div className={`${tried && !validEmail && "has-error"}`}>
                    <Input
                        placeholder="Enter your temmate’s email address"
                        className="dev-email"
                        value={developerEmail}
                        onChange={e => this.changeState({"developerEmail": e.target.value})}
                    />
                    <span className="error-label">{ errorMsg }</span>
                </div>

                <Button type="default" className="pixel-btn" onClick={this.validateEmail}>Send Code</Button>
            </div>
        );
    }
};

export default SendCodeToTeammate;