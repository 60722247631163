import React from 'react';
import './index.scss';
import { Button, Input, Icon } from 'antd';
import { ReactComponent as Wordpress } from '../../../../assets/images/icons/wordpress.svg';
import { BASE_URL } from '../../../../config';
import { connect } from 'react-redux';
import { CDN_URL } from '../../../../config';
import ClickToCopy from '../../Partials/ClickToCopy';

const PixelInstallOptions = (props) => {
    const { id, dom_hash } = props;

    return (
        <div className="pixel-install-options">
            <p className="disclaimer">
                Add {props.auth.host.franchise_name} snippet to your website header. Add it to every page of your website where you want to show {props.auth.host.franchise_name} Callouts:
            </p>

            <Input.TextArea
                autosize={{ minRows: 2 }}
                disabled={true}
                value={`<script src='//${CDN_URL.replace('https://', '')}/js/main/${dom_hash}.js'></script>`}
            />

            <ClickToCopy
                defaultText="Copy Code"
                textToCopy={`<script src='//${CDN_URL.replace('https://', '')}/js/main/${dom_hash}.js'></script>`}
            >
                <Button className="click-to-copy-btn">
                    Copy Code
                </Button>
            </ClickToCopy>

            <p className="disclaimer or">OR</p>

            <p className="disclaimer wordpress-text">
                Download our WordPress plugin, install it on your website to activate it:
            </p>

            <Button
                href={`${BASE_URL}downloads/getwpplugin/${id}`}
                target="_blank"
                rel="no-opener no-referrer"
                type="default"
                className="pixel-btn wordpess-button"
            >
                <Icon component={Wordpress} />
                Download Plugin
            </Button>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(PixelInstallOptions);