import React, { Component } from 'react';
import './index.scss';
import { Form, Icon, Input, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { ReactComponent as ArrowRight } from '../../../assets/images/icons/arrowright.svg';

class OnboardingForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { dom_name, dom_domain } = values;
                this.props.add_domain(dom_name, dom_domain);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit} className="onboarding-form">
                <Form.Item>
                    <p className="input-label">I work at</p>

                    <Tooltip overlayClassName="onboarding-tooltip" placement="right" title="You can use your own name, your company’s name or your client’s name here. This is for your own reference.">
                        {getFieldDecorator('dom_name', {
                            rules: [{ required: true, message: 'Please input your company name!' }],
                        })(
                            <Input placeholder="Your Company Name" />
                        )}
                    </Tooltip>

                </Form.Item>
                <Form.Item>
                    <p className="input-label label-2">My website is</p>
                    <Tooltip overlayClassName="onboarding-tooltip" placement="right" title={`You can enter your website domain or any other domain that you intend to use ${this.props.auth.host.franchise_name} on.`}>
                        {getFieldDecorator('dom_domain', {
                            rules: [
                                { required: true, pattern: /^(https?:\/\/)?([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/, message: 'Please input your website!' },
                            ],
                        })(
                            <Input placeholder="yourwebsite.com" />
                        )}
                    </Tooltip>
                </Form.Item>
                <Form.Item>
                    <Button type="default" htmlType="submit">
                        Continue <Icon component={ArrowRight} />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default Form.create({ name: 'onboarding_form' })(connect(mapStateToProps)(OnboardingForm));