import React from 'react';
import './index.scss';
import { Row, Col } from 'antd';
import CampaignCard from '../../Partials/CampaignCard';

const LastFewCampaigns = (props) => {
    return(
        <div className="last-few-campaigns">
            <Row>
                {
                    props.campaigns.map((campaign, i) => <Col xs={24} className="column" key={campaign._id.$id}>
                        <CampaignCard
                            campaign={campaign}
                            toggleCalloutStatus={(key, status, campid) => props.toggleCalloutStatus(key, status, campid)}
                            toggleCampaignStatus={(campid, status) => props.toggleCampaignStatus(campid, status)}
                            toggleCampaignArchive={(campid, archived) => props.toggleCampaignArchive(campid, archived)}
                            cardState={ i=== 0 ? 'open' : 'closed' }
                        />
                    </Col>)
                }
            </Row>
        </div>
    );
};

export default LastFewCampaigns;